import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Tickets = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                QHSE/Bât
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {
                    (props.roles && (props.roles.includes("ROLE_INTERNAL") || props.roles.includes("ROLE_ADMIN"))) && (
                        <Fragment>
                            <li>
                                <a href={"/qhse/visit/form"} className="dropdown-item">
                                    Ticket visiteurs
                                </a>
                            </li>
                            <li>
                                <a href={"/qhse/visit/list"} className="dropdown-item">
                                    Liste des visiteurs
                                </a>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <a href={"/qhse/bbso/form"} className="dropdown-item">
                                    Formulaire BBSO
                                </a>
                            </li>
                            <li>
                                <a href={"/qhse/bbso/list"} className="dropdown-item">
                                    Liste BBSO
                                </a>
                            </li>
                            {
                                (props.roles.includes("ROLE_QHSE") || props.roles.includes("ROLE_ADMIN")) &&
                                <li>
                                    <a href={"/qhse/bbso/goals"} className="dropdown-item">
                                        Liste des objectifs
                                    </a>
                                </li>
                            }
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <a href={"/qhse/quality-feedback/form"} className="dropdown-item">
                                    Formulaire remontées qualité
                                </a>
                            </li>
                            <li>
                                <a href={"/qhse/quality-feedback/list"} className="dropdown-item">
                                    Liste remontées qualité
                                </a>
                            </li>

                            {
                                (props.roles.includes("ROLE_QHSE") || props.roles.includes("ROLE_ADMIN")) &&
                                <Fragment>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li>
                                        <a href={"/qhse/building/form"} className="dropdown-item">
                                            Formulaire remontées bâtiment
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/qhse/building/list"} className="dropdown-item">
                                            Liste remontées bâtiment
                                        </a>
                                    </li>
                                </Fragment>
                            }

                            {
                                (props.roles.includes("ROLE_QHSE") || props.roles.includes("ROLE_ADMIN")) &&
                                <Fragment>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li>
                                        <a href={"/qhse/equipment/list"} className="dropdown-item">
                                            Liste des équipements
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/qhse/equipment/request/new"} className="dropdown-item">
                                            Nouvelle demande d'équipements
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/qhse/equipment/request/list"} className="dropdown-item">
                                            Liste de demande d'équipements
                                        </a>
                                    </li>
                                </Fragment>
                            }

                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <a href={"/qhse/chemical-hazard"} className="dropdown-item">
                                    Risque Chimique
                                </a>
                            </li>

                            <div className="dropdown-divider"></div>

                            <li>
                                <a href={"/annoucement"} className="dropdown-item">
                                    Annonces
                                </a>
                            </li>
                        </Fragment>
                    )
                }
            </ul >
        </li >
    )

}

export default Tickets;