import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage, { InfoMsg } from "../../common/smallComponents.js/ErrorMessage";
import { arrayRemoveElt, sortArrayNumber } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import { downloadTempFile } from "../../services/functions/FilesProcess";
import Movement from "../../services/storehouse/Movement";
import DeltaScan from "./inventory/DeltaScan";
import FocusTable from "./inventory/FocusTable";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ProgressBar } from "react-bootstrap";

const AnnualIventCount = (props) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState();
    const [cursor, setCursor] = useState(-1);
    const [qty, setQty] = useState();
    const [lastCursor, setLastCursor] = useState(0);
    const [saving, setSaving] = useState(false);
    const [secondCount, setSecondCount] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {

        if (!user || !user.trigram) {
            setError("Votre trigramme n'est pas renseigné dans votre profil");
        } else {
            Movement.getFocusInventoryTable(user.trigram.trim()).then(
                (res) => {
                    setLoading(false);

                    if (!res.list || res.list.length === 0) {
                        setError("Aucun article ne vous a été affecté");
                    } else {
                        initCursor(res.list, res.secondCount ? "stock_2" : "stock");
                        setList(res.list);
                        setSecondCount(res.secondCount);
                    }
                }, (error) => {
                    setLoading(false);
                    setError("Aucun article ne vous a été affecté");
                }
            )
        }

    }, []);

    useEffect( () => {
        var field = secondCount ? "stock_2" : "stock";
        if(  cursor >= 0 && list[cursor]  ){
            if( list[cursor][field] === 0 ){
                setQty( 0 );
            }else{
                setQty( list[cursor][field] || "");
            }
            
        } else{
            setQty();
        }
    }, [cursor])

    const initCursor = (arr, field) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][field] === null) {
                setCursor(i);
                setLastCursor(i);
                setQty(arr[i][field]);
                return;
            }
        }

        setCursor(arr.length - 1);
        setLastCursor(arr.length - 1);
        setQty(arr[arr.length - 1][field]);
    }

    const save = () => {
        if (!qty) {
            setError("Saissez la quantité comptée !");
            return;
        }

        //Save in database
        var field = secondCount ? "stock_2" : "stock";
        var arr = [...list];
        arr[cursor][field] = qty;
        setList(arr);
        
        Movement.updateFocusInventoryTable(list[cursor].CDARL, field, qty);

        //Go to next and reset
        var c = cursor + 1;
        setCursor(c); setLastCursor(c);
        setQty();
        document.getElementById("qty").value = "";
        document.getElementById("qty").focus();
    }

    return <div className="text-center">
        <h5 className="text-center">Inventaire annuel</h5>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}

        {list && <Fragment>
            <ProgressBar now={(((cursor+1) / list.length) * 100).toFixed(0)}
                label={<span
                    style={{
                        position: 'absolute', left: '50%', transform: 'translateX(-50%)',
                        width: '100%', textAlign: 'center'
                    }}
                >{(cursor + 1) + " / " + (list.length)}</span>} />

            {(cursor !== -1 && cursor < list.length) && <div>


                <h6 className="mt-3">{list[cursor].CDARPA.trim()} {(list[cursor].ZN15PA && list[cursor].ZN15PA.trim() !== "") &&
                    <span> / {list[cursor].ZN04PA}</span>}</h6>

                <div className="mb-3">{list[cursor].ZN02PA.trim()}</div>
                <div className="fst-italic">{list[cursor].ZN03PA.trim()}</div>

                <br></br>
                <div className="col-8 offset-2 mb-3">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Qté</span>
                        <input type="number" id="qty" className="form-control text-center fw-bold"
                            value={qty} onChange={(e) => { setQty(e.target.value); setError() }} />
                    </div>
                </div>
                <br></br>
                {cursor >= list.length && <div className="mb-3">
                    <h6 className="mb-3">Fin d'inventaire</h6>
                    <img style={{ width: "90%" }} src="/img/storehouse/funny-end.gif" />
                </div>}

            </div>}

            {(list && cursor >= list.length) && <div>
                <div className="mb-3">
                    <h6 className="mb-3">Fin d'inventaire</h6>
                    <img style={{ width: "90%" }} src="/img/storehouse/funny-end.gif" />
                </div>
            </div>}

            <div className="d-flex">
                {cursor > 0 && <button className="btn btn-secondary" onClick={() => setCursor(cursor - 1)}>&lt;</button>}

                {cursor < list.length &&
                    <button className="btn btn-success ms-auto me-auto" onClick={save} disabled={saving} style={{ marginLeft: "10px" }}>
                        {saving && <ButtonWaiting />}
                        Valider
                    </button>}

                {(cursor < lastCursor && cursor < list.length) && <button className="btn btn-secondary" onClick={() => setCursor(cursor + 1)}>&gt;</button>}
            </div>

        </Fragment>}


    </div>

}

export default AnnualIventCount;