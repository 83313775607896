import React, { useState, useEffect, useRef } from "react";
import { getDeviceType } from "../functions/PageAnimation";
import { Fragment } from "react";
import CheckConnDao from "../services/common/CheckConnDao";
import { getFrFormat } from "../functions/StrFunctions";
import i18n from "../i18n";
import { useCookies } from "react-cookie";
import { arrayRemoveElt } from "../functions/ArrayFunctions";
import NavigationSearch from "./settings/comp/NavigationSearch";
import Miscellaneous from "../services/common/Miscellaneous";


const Home = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  var typeDevice = getDeviceType();
  const [list, setList] = useState([]);
  const [read, setRead] = useState([]);
  const canNavigate = user && (user.roles.includes("ROLE_ADMIN"))

  const [cookies, setCookie] = useCookies(['name']);
  const [canScan, setCanScan] = useState(false);

  useEffect(() => {
    //Cookies read annoucement
    setRead(cookies["annoucement_read"] || []);

    var read = cookies["annoucement_read"] || [];
    CheckConnDao.getAnnouncements(false).then((r) => {
      var arr = new Array();
      r.map(v => { if (!read.includes(v.id)) arr.push(v) })
      setList(arr);

      setTimeout(() => {
        if( arr.length > 1 )document.getElementById('go-next-carousel').click();
      }, 5000);
    }, () => { });

    if (canNavigate) {
      setTimeout(() => {
        let nav = document.getElementById("searchEngine");
        if (nav)
          nav.querySelector("#ipt").focus()
      }, 200);
    }

    Miscellaneous.getStateByType("rotating_inventory_scan_ar").then(
      (r) => { if (r) setCanScan(r.value1 === "true") }
    )
  }, []);


  function getImgStyle() {
    //if (list.length === 0) return { width: "100%" };

    return { position: "fixed", width: "100%", left: "0", top: "60px" }
  }

  const markAsRead = (id, k) => {
    var arr = [...read];
    arr.push(id);
    setRead(arr);

    var cookiesExpiration = new Date();
    cookiesExpiration.setFullYear(new Date().getFullYear() + 1);

    setCookie("annoucement_read", arr, { expires: cookiesExpiration });

    var arr2 = [...list];
    arr2 = arrayRemoveElt(arr2, k);
    setList(arr2);
  }

  return (
    <div>


      {
        typeDevice === "desktop" && <div className="home-bg">

          {/*canNavigate && <NavigationSearch />}
          <div className="container text-center mb-3">
            <img src="/common/logo_fond_ecran custom.png" style={getImgStyle()}></img>
          </div>*/}

          {list.length === 0 && <div className="text-center h-100">
            <img src="/common/logo_fond_ecran no back.png" className="center-img" style={{ width: "300px", maxHeight: "301px" }} />
          </div>
          }

          {list.length > 0 &&
            <div id="carouselExampleRide" className="carousel slide mt-2" data-bs-ride="true"
              style={{ height: "85vh" }}>
              <div className="carousel-inner text-center">


                {list.map((v, k) => {

                  return <div className={"carousel-item" + (k === 0 ? " active" : "")} data-bs-interval="3000">
                    {v.url && <img src={v.url} className="mb-3 rounded" style={{ maxWidth: "800px", maxHeight: "80vh" }} />

                    }
                    <div dangerouslySetInnerHTML={{ __html: i18n.language === "fr" ? v.msg_fr : v.msg_en }}
                      className="mt-auto mb-auto"></div>
                  </div>

                })}

              </div>

              {list.length > 1 && <Fragment>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>

                <button className="carousel-control-next" id="go-next-carousel" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </Fragment>
              }


            </div>
          }


        </div>
      }

      {
        (typeDevice !== "desktop" && (user && user.roles && (user.roles.includes("ROLE_STOREHOUSE") || user.roles.includes("ROLE_ADMIN")))) &&
        <div className="text-center mb-3">
          <div className="container text-center mb-3">
            <img src="/Logo B+ Eq SealedAir.png" style={{ width: "100%" }}></img>
          </div>

          <h5 style={{ color: "rgb(0, 137, 150)", fontSize: "30px" }}>Mini Jarvis</h5>

          <a className="btn btn-outline-secondary mb-3"
            href="/storehouse/scan/move-out" style={{ minWidth: "220px", fontSize: "20px" }}>Sortie de pièces</a>
          <br></br>
          <a className="btn btn-outline-secondary mb-3"
            href="/storehouse/scan/move-in" style={{ minWidth: "220px", fontSize: "20px" }}>Entrée de pièces</a>
          <br></br>
          <a className="btn btn-outline-secondary mb-3"
            href="/subcontracting/scan" style={{ minWidth: "220px", fontSize: "20px" }}>Scan colisage</a>
          <br></br>

          <div className="accordion ms-auto me-auto" id="accordionExample" style={{ width: "300px" }}>
            <div className="accordion-item" style={{ borderColor: "#6c757d" }}>
              <h2 className="accordion-header">
                <button className="accordion-button collapsed text-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <div className="ms-auto text-center" style={{ fontSize: "20px" }}>Inventaire</div>
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <a className="btn btn-outline-secondary mb-3"
                    href="/storehouse/articles/inventory/scan" style={{ minWidth: "220px", fontSize: "20px" }}>Scan prépa inventaire</a>
                  <br></br>
                  <a className="btn btn-outline-secondary mb-3"
                    href="/storehouse/articles/inventory/count" style={{ minWidth: "220px", fontSize: "20px" }}>Inventaire annuel</a>
                  <br></br>
                  <a className="btn btn-outline-secondary mb-3"
                    href="/storehouse/rotating/inventory/scan" style={{ minWidth: "220px", fontSize: "20px" }}>Inventaire tournant</a>
                  {canScan && <Fragment>
                    <br></br>
                    <a className="btn btn-outline-secondary mb-3"
                      href="/storehouse/rotating/inventory/scan?page=scan_location" style={{ minWidth: "220px", fontSize: "20px" }}>Scan libre</a>
                  </Fragment>
                  }
                </div>
              </div>
            </div>
          </div>


        </div>
      }

      {
        (typeDevice !== "desktop" && (user && user.roles && (user.roles.includes("ROLE_TRANSPORT") || user.roles.includes("ROLE_TRANSPORT")))) &&
        <div className="text-center mb-3">
          <br></br>
          <h5 style={{ color: "rgb(0, 137, 150)", fontSize: "30px" }}>Mini Jarvis</h5>
          <a className="btn btn-outline-secondary mb-3"
            href="/storehouse/articles/screws/scan" style={{ minWidth: "220px", fontSize: "20px" }}>Commande visseries</a>
        </div>
      }

    </div>
  );
};

export default Home;
