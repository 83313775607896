import { Fragment, useEffect, useState } from "react";
import getUrlParam from "../../functions/StrFunctions";
import GetMachNuser from "./ProdTracking/v2/GetMachNuser";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ChooseActivity from "./ProdTracking/v2/ChooseActivity";
import { isSideBarOpened, SideNavBarV3 } from "../../common/smallComponents.js/DesignedIpt";
import ActivityMngt from "./ProdTracking/v2/ActivityMngt";

const ProdTrackingV2 = (props) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [pin, setPin] = useState(isSideBarOpened());

    const me = JSON.parse(localStorage.getItem("user"));
    const isAdmin = me && (me.roles.includes("ROLE_ADMIN") || me.roles.includes("ROLE_WORKSHOP_N1"));

    const [step, setStep] = useState("");
    const [machine, setMachine] = useState();
    const [user, setUser] = useState();
    const [bom, setBOM] = useState();
    const [status, setStatus] = useState();
    const [activity, setActivity] = useState();

    const [machineParam, setMachineParam] = useState();
    const [trigramParam, setTrigramParam] = useState();

    useEffect(() => {
        var param = getUrlParam(props, "step");
        var machine = getUrlParam(props, "machine");
        var trigram = getUrlParam(props, "trigram");

        if (param) setStep(param);
        if (machine) setMachineParam(machine);
        if (trigram) setTrigramParam(trigram);

    }, []);


    const changeWorkstation = () => {
        setStep("choose_activity");
        var o = {...user};
        o.workstation = null;
        setUser(o);
    }

    return <div className="bg-see navbarV3 right-side">
        <SideNavBarV3 setPin={setPin} content={<div className="ps-2">

            {step !== "" && <Fragment>
                {isAdmin &&
                    <div className="mb-3 mt-3">
                        <a href="#" onClick={changeWorkstation}>Changer de poste</a>
                    </div>
                }

                <div className="mb-3">
                    <a href={"/workshop/production/tracking/v2?trigram=" + user.trigram}>Changer de machine</a>
                </div>

                <div className="mb-3 mt-3">
                    <a href={"/workshop/production/tracking/v2?machine=" + machine.no_machine}>Changer d'utilisateur</a>
                </div>

                <div className="mb-3 mt-3">
                    <a href={"/workshop/production/tracking/v2"}>Changer d'utilisateur et machine</a>
                </div>

            </Fragment>}

            {step === "activity_mngt" && 
                <div className="mb-3">
                    <a href="#" onClick={()=>{setStep("choose_activity"); setActivity()}}>Faire un autre sous-ensemble</a>
                </div>
            }

        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            {error && <ErrorMessage error={error} errorState={setError} />}
            {loading && <WaitingRoundSnippers />}

            {step === "" && <GetMachNuser setError={setError} setStep={setStep} props={props}
                loading={loading} setLoading={setLoading} setMachine={setMachine}
                setUser={setUser} setBOM={setBOM} setStatus={setStatus}
                machineParam={machineParam} trigramParam={trigramParam} />}

            {machine &&
                <div className="card ms-auto me-auto p-2 mb-3" style={{ margin: 0, width: "300px" }}>
                    <h5 className="text-center">Machine {machine.no_machine} ({machine.typ_config})</h5>
                    <h6 className="text-center">Affaire {machine.affaire}</h6>
                    <h6 className="text-center">{user.last_name} {user.first_name} ({user.workstation || "?"})</h6>
                </div>
            }

            {step === "choose_activity" &&
                <ChooseActivity machine={machine} user={user} bom={bom} status={status} 
                    setUser={setUser} setStep={setStep} setActivity={setActivity}/>}

            { (step === "activity_mngt" && activity) && 
                <ActivityMngt activity={activity} setActivity={setActivity} machine={machine} user={user} bom={bom} status={status} 
                    setStep={setStep} setStatus={setStatus} setLoading={setLoading} setError={setError}
                    props={props} loading={loading}/>
            }
        </div>
    </div>
}

export default ProdTrackingV2;