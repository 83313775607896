import React, { useState } from 'react';
import CheckConnDao from '../../services/common/CheckConnDao';
import { ButtonWaiting } from '../../functions/PageAnimation';
import errorManagement from '../../services/errorManagement';
import ErrorMessage from '../../common/smallComponents.js/ErrorMessage';
import SuccessMessage from '../../common/smallComponents.js/SuccessMessage';


const Test = (props) => {
  const [translatedText, setTranslatedText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [msg, setMsg] = useState();

  const translate = async (e) => {
    e.preventDefault();
    var val = e.target.text.value;
    
    const translation = await CheckConnDao.translate(val, 'ZH');
    setTranslatedText(translation)
  }

  const testSendEmail = () => {
    setLoading(true);

    CheckConnDao.testSendMail().then(() => { setMsg("ok"); setLoading(false); }, 
      (error) => {
        setError(errorManagement.handleError(props, error));
        setLoading(false);
    });
  }

  return <div>
    {error && <ErrorMessage error={error} />}
    {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

    <h6 className='text-center'>Test envoi mail</h6>
    <div className='text-center'>
    <button className='btn btn-success' disabled={loading} onClick={testSendEmail}>
      {loading && <ButtonWaiting />}
      Envoyer</button>
    </div>
    
  </div>
};

export default Test;