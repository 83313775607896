import axios from "axios";
import textPlainHeader from "../fake-header";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL + "miscellaneous";
const user = JSON.parse(localStorage.getItem("user"));

const updateState = (type, value) => {
    return axios
        .post(API_URL + "/update", {
            fake_header: authHeader(),
            type: type,
            value: value
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getStateByType = (type) => {
    return axios
        .post(API_URL + "/get/byType", {
            fake_header: authHeader(),
            type: type
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    updateState,
    getStateByType
}