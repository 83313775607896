import { Fragment, useEffect, useState } from "react";
import { removeAndReturnArray } from "../../../../functions/ArrayFunctions";
import { getHeight } from "../../../../functions/PageAnimation";
import { forceString } from "../../../../functions/StrFunctions";

const FocusPicking = (p) => {
    const [cursor, setCursor] = useState("");
    const [toAdd, setToAdd] = useState();
    const [list, setList] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [skeleton, setSkeleton] = useState([]);
    const [type, setType] = useState("container");

    const insertContent = (e) => {
        e.preventDefault();

        var id;

        if (toAdd.k === -1) {
            id = "1";
        } else {
            //Get where we want to add it
            var level = e.target.insertIn.value;
            console.log(level)
        }

        var arr = [...skeleton];
        arr.push({
            id: id,
            type: e.target.type.value
        })

        //setSkeleton(arr);

        setToAdd();
        setContentList();
    }

    const addRow = (arr) => {

    }

    function incrementVersionTree(objects, newRowIndex, o, requestedLevel) {
        let newObjects = JSON.parse(JSON.stringify(objects)); // Deep copy to avoid mutating the original array
   
        // Split each version number into parts for each object
        newObjects.forEach(obj => {
            obj.idParts = forceString( obj.id ).split('.').map(Number);
        });
    
        // Determine the version number for the new row based on the requested level
        let precedingVersion = newObjects[newRowIndex - 1].idParts;
        let newVersion = [];
   
        // Build the new version based on the requested level
        if (requestedLevel > precedingVersion.length) {
            // If the requested level is deeper, extend the preceding version
            newVersion = [...precedingVersion];
            while (newVersion.length < requestedLevel - 1) {
                newVersion.push(0); // Fill missing levels with zero
            }
            newVersion.push(1); // Start the new subversion from 1
        } else {
            // Otherwise, increment the existing level
            if( requestedLevel === 1 ){
                newVersion.push(precedingVersion[0] + 1); 
            }else{
                newVersion = [...precedingVersion.slice(0, requestedLevel - 1)];
                newVersion.push(precedingVersion[requestedLevel - 1] + 1); 
            }
                      
        }

        // Add the new row into the array
        o.id = newVersion.join('.');
        newObjects.splice(newRowIndex, 0, {
            ...o
        });
    
        // Adjust the following rows
        for (let i = newRowIndex + 1; i < newObjects.length; i++) {
            let currentVersion = newObjects[i].idParts;
            if (currentVersion.length >= requestedLevel) {
                currentVersion[requestedLevel - 1]++;
                if (currentVersion.length > requestedLevel) {
                    currentVersion = currentVersion.slice(0, requestedLevel); // Trim sub-levels
                }
                newObjects[i].id = currentVersion.join('.');
            }
        }
    
        // Clean up temporary idParts field
        newObjects.forEach(obj => {
            delete obj.idParts;
        });
    
        return newObjects;
    }

    const insertArticle = (e) => {
        e.preventDefault();

    }

    useEffect(() => {
        if (!toAdd || toAdd.k === -1) return;

        var done = [];
        var arr = [];
        //Get content list where to add the article
        for (let i = toAdd.k; i >= 0; i--) {
            var nbDot = skeleton[i].id.split(".").length;
            if (!done.includes(nbDot)) {
                arr.push(skeleton[i]);
                done.push(nbDot);
            }
        }

        setContentList(arr)

    }, [toAdd])

    return <Fragment>
        {p.picking && <Fragment>
            <table className="table">
                <thead className="text-center">
                    <tr>
                        <th style={{ width: "30px", borderBottomStyle: "none", borderTopStyle: "none" }}></th>
                        <th scope="col">ID</th>
                        <th scope="col">Qté</th>
                        <th scope="col">Article</th>
                        <th scope="col">Désignation</th>
                        <th scope="col">Type de colisage</th>
                        <th scope="col">Longueur</th>
                        <th scope="col">Largeur</th>
                        <th scope="col">Hauteur</th>
                        <th scope="col">Poids</th>
                        <th scope="col" className="no-print"></th>
                    </tr>
                </thead>
                <tbody>
                    {skeleton.map((v2, k2) => {
                        return <Fragment>
                            <tr key={"pick" + "_" + k2} onMouseEnter={() => setCursor("_" + k2)}
                                onMouseLeave={() => setCursor("")}>
                                <td>{v2.id}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{v2.type}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <img src="/common/remove_icon.png" className="see-img w-30 cursor-pointer"
                                        onClick={() => setSkeleton(removeAndReturnArray(skeleton, k2))} />
                                </td>
                            </tr>

                            <tr key={"add_" + k2} onMouseEnter={() => setCursor("_" + k2)}
                                onMouseLeave={() => setCursor("")} title="Ajouter un élément"
                                className={cursor === "_" + k2 ? "no-print" : "no-print d-none"}>

                                <td style={{ width: "30px", borderBottomStyle: "none", borderTopStyle: "none" }}
                                    className="cursor-pointer position-relative p-0"
                                    onClick={() => setToAdd({ k: k2 })}>
                                    <img src="/common/add_btn.png" className="position-absolute"
                                        style={{ maxWidth: "30px", marginTop: "-15px", zIndex: "1000" }} />
                                </td>

                                <td colSpan={11} className="see-border p-0"></td>
                            </tr>
                        </Fragment>
                    })}
                </tbody>
            </table>

            {skeleton.length === 0 &&
                <div className="text-center">
                    <button className="btn btn-secondary" onClick={() => setToAdd({ k: -1, type: "skeleton" })}>Ajouter un contenant</button>
                </div>}


            {toAdd && <div className="custom-tooltip-v2">
                <div className="modal-body xl blue-purple-bg">
                    <button type="button" className="btn-close" onClick={() => setToAdd()}></button>

                    <h5 className="text-center">Insérer un contenant ou articles</h5>

                    <div className="text-center mb-3">
                        <input type="radio" className="btn-check" name="type" checked={type === "container"}
                            onClick={() => setType("container")} />
                        <label className="btn" onClick={() => setType("container")}>Contenant</label>

                        {skeleton.length >= 0 && <Fragment>
                            <input type="radio" className="btn-check" name="type" checked={type === "articles"}
                                onClick={() => setType("articles")} />
                            <label className="btn" onClick={() => setType("articles")}>Articles</label>
                        </Fragment>
                        }
                    </div>

                    {type === "container" &&
                        <form onSubmit={insertContent} className="ms-auto me-auto" style={{ width: "800px" }}>

                            {contentList && <div>
                                <div className="input-group mb-3">
                                    <label className="input-group-text">Insérer dans</label>
                                    <select className="form-select" name="insertIn" required>
                                        <option value="-1">Premier niveau</option>
                                        {contentList.map((v, k) => {
                                            return <option value={v.id} key={"insert" + k}>{v.id} - {v.type}</option>
                                        })}
                                    </select>

                                </div>
                            </div>}

                            <div className="input-group mb-3">
                                <label className="input-group-text">Type de colisage</label>
                                <select className="form-select" name="type" required>
                                    <option value="">Choose...</option>
                                    <option value="Complet">Container</option>
                                    <option value="Palette">Palette</option>
                                    <option value="Caisse">Caisse</option>
                                    <option value="Carton">Carton</option>
                                    <option value="Enveloppe">Enveloppe</option>
                                </select>

                            </div>

                            <div className="text-center">
                                <button className="btn btn-success">Ajouter</button>
                            </div>
                        </form>}

                    {type === "articles" &&
                        <form onSubmit={insertArticle}>

                            {contentList && <div>
                                <div className="input-group mb-3">
                                    <label className="input-group-text">Insérer dans</label>
                                    <select className="form-select" name="insertIn" required>
                                        {contentList.map((v, k) => {
                                            return <option value={v.id} key={"insert" + k}>{v.id} - {v.type}</option>
                                        })}
                                    </select>

                                </div>
                            </div>}

                            <div className="overflow-y-auto mb-3" id="art_proposal" style={{ height: "500px" }}>
                                <table className="table">
                                    <thead>
                                        <tr className="table-info text-center">
                                            <th className="text-start">
                                                <input className="form-check-input" type="checkbox" />
                                            </th>
                                            <th>Article</th>
                                            <th>Ref / plan</th>
                                            <th>Libellé</th>
                                            <th>Origine</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {p.picking.picking.map((v, k) => {
                                            return <tr key={"prop" + k}>
                                                <td><input className="form-check-input" type="checkbox" /></td>
                                                <td>{v.CDARL}</td>
                                                <td>{v.ZN02PA}</td>
                                                <td>{v.ZN03PA}</td>
                                                <th>{v.source}</th>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-success">Ajouter</button>
                            </div>
                        </form>
                    }

                </div>
            </div>}
        </Fragment>
        }



    </Fragment>
}

export default FocusPicking;