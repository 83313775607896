
/**
 * Simple divider
 */
export const Divider = () => {
    return <div className="divider-v2">
        <div class="h-divider"><div class="shadow"></div></div>
    </div>
}

/**
 * p.text
 * @param {*} p 
 */
export const DividerWithTxt = (p) => {
    return <div className="divider-v2">
        <div class="h-divider">
            <div class="shadow"></div>
            <div class="text"><i dangerouslySetInnerHTML={{ __html: p.text }}></i></div>
        </div>
    </div>
}