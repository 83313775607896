import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { SideNavBarV3, isSideBarOpened } from "../../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { addOrUpdateObj, arrayRemoveElt, removeAndReturnArray, updateSimpleArray } from "../../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import getUrlParam, { getDate, getDateTimeFromDb, getFrFormat } from "../../../functions/StrFunctions";
import errorManagement from "../../../services/errorManagement";
import { downloadTempFile } from "../../../services/functions/FilesProcess";
import orderInfo from "../../../services/purchase/orderInfo";
import contactsDao from "../../../services/settings/contactsDao";
import transport from "../../../services/supplyChain/transport";
import { RequestPrice, SendToPacker } from "./SmallComp";

const ShipmentRequest = (p) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [searchOrder, setSearchOrder] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const isSupplyChain = user && (user.roles.includes("ROLE_SUPPLY_CHAIN") || user.roles.includes("ROLE_ADMIN"));
    const isPurchase = user && (user.roles.includes("ROLE_PURCHASE") || user.roles.includes("ROLE_ADMIN"));
    const isAdmin = user &&  user.roles.includes("ROLE_ADMIN");

    const [machines, setMachines] = useState();
    const [form, setForm] = useState();

    const [ship, setShip] = useState();
    const [shipDetail, setShipDetail] = useState([]);
    const [businessInfo, setBusinessInfo] = useState({});
    const [headerId, setHeaderId] = useState();
    const [truckChoosen, setTruckChoosen] = useState();
    const [orderFocus, setOrderInfo] = useState({});
    const [sendMail, setSendMail] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [drawings, setDrawings] = useState();
    const [upload, setUpload] = useState();
    const [formOriginChange, setFormOriginChange] = useState([]);

    const [packerContacts, setPackerContacts] = useState();
    const [sendToPacker, setSendToPacker] = useState();

    const [purchaser, setPurchaser] = useState([
        { name: "Blanchet, Mary", mail: "mary.blanchet@sealedair.com", tel: "+33442361571", checked: true },
        { name: "Jing, Guole", mail: "lola.jing@sealedair.com", tel: "+33442361561", checked: true },
        { name: "Kornejev, Olga", mail: "olga.kornejev@sealedair.com", tel: "+33442361553", checked: false },
        { name: "Babilotte, Mingjia", mail: "mingjia.babilotte@sealedair.com", tel: "+33442361561", checked: false },
        { name: "Idri, Corinne", mail: "corinne.idri@sealedair.com", tel: "+33442361503", checked: false },
        { name: "Piloyan, Catherine", mail: "catherine.piloyan@sealedair.com", tel: "+33628597930", checked: false },
        { name: "VILLONI, Valerio", mail: "valerio.villoni@sealedair.com", tel: "+33608901088", checked: false }
    ]);

    useEffect(() => {
        var header_id = getUrlParam(p.props, "shipment_id");
        setHeaderId(header_id);

        var origin = getUrlParam(p.props, "origin");

        //if this page is opened from email check if isn't already done
        if (origin && origin === "email") {
            transport.shipmentExists(header_id).then(
                (r) => {
                    //console.log(r)
                    if (r.length > 0) {
                        window.location.href = "/supply-chain/tools/expedition?page=shipment-request&shipment_id="
                            + header_id + "&id=" + r[0].id
                    } else {
                        getExpedition(header_id);
                    }


                }, (error) => {
                    setError(errorManagement.handleError(p.props, error));
                    scrollToTop();
                    setLoading(false);
                }
            )
        } else {
            getExpedition(header_id);
        }


        contactsDao.getContactsByGroup("TRANPORT_PRICE_REQUEST").then(
            (r) => { setContacts(r) }, () => { }
        )
    }, []);

    function getExpedition(header_id) {
        transport.getShipmentForm(getUrlParam(p.props, "id"), header_id).then(
            (r) => {
                console.log(r)
                r.shipmentId = header_id;
                setForm(r);
                setShip(r.shipmentOrigin);
                setBusinessInfo(r.businesInfo);
                if (r.detail.length === 0) { addShipping(); }
                else { setShipDetail(r.detail); truckCheck(r.detail); }
                getMachines(r.shipmentOrigin.machines)

                if (r.order_nb) {
                    setSearchOrder(true)
                    orderInfo.getOrderInfo(r.order_nb).then((r) => { setOrderInfo(r); setSearchOrder(false) });
                }

                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    function getMachines(strMach) {
        var arr = JSON.parse(strMach);
        var str = "";
        arr && arr.map(v => str += v.label + ", ")
        setMachines(str.substring(0, str.lastIndexOf(",")))
    }

    /** On any change in form */
    const onFormChange = (field, e) => {

        var o = { ...form };
        if (field === "processing") {
            o[field] = e.target.checked ? "yes" : null;
        } else {
            o[field] = e.target.value;
        }

        setForm(o);

        //If we change the shipment track it
        if (form.id) checkChange(o, field, e)
    }

    const checkChange = (obj, field, e) => {
        var label = e.target.parentElement.getElementsByClassName("input-group-text")[0].innerText;

        if (obj[field] !== form[field]) {
            var c = {
                field: field,
                oldVal: form[field],
                newVal: obj[field],
                label: label
            }

            var arr = [...formOriginChange];
            setFormOriginChange(addOrUpdateObj(arr, "field", field, c));
        }
    }


    const send = (send) => {
        setError();

        var o = {
            businessInfo: businessInfo,
            shipmentOrigin: ship,
            form: form,
            shipDetail: shipDetail,
            sent: send,
            formOriginChange: formOriginChange
        }

        //if want to send check if all infos are filled
        if (send) {
            if (shipDetail.length === 0) {
                setError("Complétez le(s) moyen(s) de transport !");
                scrollToTop();
                return;
            } else if (!form.drawing) {
                confirmAlert({
                    title: "Confirmation",
                    message: "Le plan de chargement n'a pas été téléchargé. voulez-vous tout de même envoyer ?",
                    buttons: [{ label: "Oui", onClick: () => saveShipment(o) },
                    { label: "Non, cherchez le plan", onClick: () => { searchDrawing(); scrollToTop() } }]
                });
            } else {
                saveShipment(o);
            }
        } else {
            saveShipment(o);
        }
    }

    function saveShipment(o) {
        //If you send the request to packer need to send a specific email
        if (ship.packer === 1 && o.sent && shipDetail.length > 0 && shipDetail[0].truck_type !== "S2C" ) {
            setPackerContacts([ship.mail_recip]);
        } else {
            setSaving(true);
            transport.saveShipmentForm(o).then(
                (r) => {
                    setSaving(false);
                    window.location.href = "/supply-chain/tools/expedition";
                }, (error) => {
                    setError(errorManagement.handleError(p.props, error));
                    scrollToTop();
                    setSaving(false);
                }
            )
        }
    }

    const addShipping = () => {
        var arr = [...shipDetail];
        var pack_pick_date, pack_delivery;

        if (shipDetail.length > 0 && truckChoosen !== "Camion") {
            pack_pick_date = arr[0].pack_pick_date;
            pack_delivery = arr[0].pack_delivery;
        }

        if (form && form.id) {
            var c = {
                field: "transport_" + shipDetail.length + 1,
                oldVal: shipDetail.length + " moyen de transport",
                newVal: shipDetail.length + "+1",
                label: "Ajout d'un moyen de transport"
            }

            var arr2 = [...formOriginChange];
            setFormOriginChange(addOrUpdateObj(arr2, "field", c.field, c));
        }

        arr.push({
            header_id: headerId,
            nb_strap: 12,
            type: truckChoosen ? "Camion" : "",
            pack_pick_date: pack_pick_date,
            pack_delivery: pack_delivery
        });
        setShipDetail(arr);
    }

    const deleteShipping = (k) => {
        confirmAlert({
            title: "Suppression",
            message: "Confirmez-vous la suppression de ce moyen de transport ? Les données seront perdues.",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var arr = [...shipDetail];
                        var id = arr[k].detail_id;
                        var type = arr[k].type;
                        var id_exp = ship.id;
                        arr = arrayRemoveElt(arr, k);
                        setShipDetail(arr);

                        //Delete in data base if has ID
                        transport.deleteDetailById(id, type, id_exp);
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const onShippingChange = (e, k, field) => {
        var val = e.target.value;
        var arr = [...shipDetail];
        var oldValue = shipDetail[k][field];
        arr[k][field] = val;


        if (field === "truck_type") {
            if (val === "TAULINER") {
                arr[k].truck_height = "2.70";
            } else if (val === "MEGA") {
                arr[k].truck_height = "2.94";
            }
        }

        //If we change the shipment track it
        if (form.id && oldValue !== val) checkChangeShipDetail(val, field, e, k, oldValue)

        setShipDetail(arr);

        if (field === "type") truckCheck(arr)

    }

    const checkChangeShipDetail = (val, field, e, k, oldValue) => {
        var label = e.target.parentElement.getElementsByClassName("input-group-text")[0].innerText;
        field = (k + 1) + "_" + field;

        var c = {
            field: field,
            oldVal: oldValue,
            newVal: val,
            label: shipDetail[k].type.trim() + (k + 1) + ': ' + label
        }

        var arr = [...formOriginChange];
        setFormOriginChange(addOrUpdateObj(arr, "field", field, c));

    }

    function truckCheck(arr) {
        var truck, filled = false;
        arr.map(v => {
            if (v.type && v.type.trim() === "Camion") truck = true;
            if (v.type && v.type.trim() !== "") filled = true;
        })

        if (!filled) { setTruckChoosen(); }
        else { setTruckChoosen(truck ? "yes" : "no") }
    }

    const searchDrawing = async () => {
        setError();
        setSaving(true);

        var business = ship.business.split(',');

        var err = "";
        var arr = [];

        for (let i = 0; i < business.length; i++) {
            await transport.searchDrawing(business[i].trim()).then(
                (r) => {
                    arr = arr.concat(r);
                }, (error) => {
                    err += errorManagement.handleError(p.props, error) + "<br>"
                }
            )
        }

        setSaving(false);
        if (err !== "") {
            setError(err);
            scrollToTop();
        }

        if (arr.length > 0) setDrawings(arr);

    }

    const selectDrawing = (path) => {
        var o = { ...form };
        o.drawing = path;
        setForm(o);
        setDrawings();
    }

    const uploadFile = (e) => {
        e.preventDefault();
        setError();
        var o = { ...upload };
        o.loading = true;
        setUpload(o);
        var t = e.target;

        transport.uploadFile(t.detail_id.value, t.file.files[0], o.type).then(
            (r) => {
                var o2 = { ...upload };
                o2.loading = false;
                o2.msg = "Ok sauvegardé !";
                setUpload(o2);
            }, (error) => {
                setUpload();
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setSaving(false);
            }
        )

    }

    const downloadFile = (type) => {
        var toDownload = [];

        if (type === "all") {
            for (let i = 0; i < shipDetail.length; i++) {
                if (shipDetail[i].del_receipt) toDownload.push(shipDetail[i].del_receipt);
                if (shipDetail[i].cmr_file) toDownload.push(shipDetail[i].cmr_file);
            }

            if (form.drawing) toDownload.push(form.drawing);
        } else if (type === "drawing") {
            if (form.drawing) toDownload.push(form.drawing);
        } else {
            for (let i = 0; i < shipDetail.length; i++)
                if (shipDetail[i][type]) toDownload.push(shipDetail[i][type])
        }

        if (toDownload.length === 0) {
            setError("Aucun fichier n'a été importé");
            scrollToTop();
        } else {
            setLoading(true);
            scrollToTop();
            transport.compressFiles(toDownload).then(
                (r) => {
                    downloadTempFile(r);
                    setLoading(false);
                }, (error) => {
                    setError(errorManagement.handleError(p.props, error));
                    scrollToTop();
                    setLoading(false);
                }
            )
        }
    }

    const cancelRequest = (e) => {
        confirmAlert({
            title: "Annulation",
            message: "Confirmez-vous la demande d'annulation de l'expédition ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        transport.cancelReqest(form).then(
                            (r) => {
                                window.location.href = "/supply-chain/tools/expedition?page=tracking&all=true";
                            }, (error) => {
                                setError(errorManagement.handleError(p.props, error));
                                scrollToTop();
                                setLoading(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const updateCom = (e) => {
        var ship_id = ship.id;
        var value = e.target.value;
        transport.updateShipField(ship_id, "com", value);
        var field = "comment";

        if (form.id && ship.com !== value) {
            var c = {
                field: field,
                oldVal: ship.com,
                newVal: value,
                label: "Commentaire"
            }

            var arr = [...formOriginChange];
            setFormOriginChange(addOrUpdateObj(arr, "field", field, c));
        }

    }

    function getOtherPack(){
        if( !ship || !ship.other_pack_id ) return "";

        var ids = JSON.parse( ship.other_pack_id );

        var txt = "";
        for( let i=0; i<ids.length; i++ ) txt += ids[i].label + ", ";
        txt = txt.substring(0, txt.lastIndexOf(","))

        return txt
    }

    const updateTruckLoadReal = (e, id) => {
        var value = e.target.value;
        transport.updateShipDetailField(id, "truck_load_real", value);
    }

    return <div className="bg-see custom-bootstrap" style={{ height: "91.5vh", marginTop: "-1.5em" }}>
        {(ship && ship.cancelled === 1) && <img className="centered-image"
            style={{ width: "300px" }} src="/common/cancelled.png"></img>}

        {/** SIDEBAR */}
        <SideNavBarV3 setPin={setPin}
            content={<div>
                <div className="img-lab mb-3">
                    <img src="/img/expeditions/boat.png"></img>
                    <div className="ms-2 fw-bold title">Demande d'expédition</div>
                </div>

                <div className="accordion" id="accordionPanelsStayOpenExample">
                    {
                        form && <Fragment>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne">
                                        Télécharger documents transp.
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="d-flex mb-3 cursor-pointer" onClick={() => downloadFile("all")}>
                                            <div style={{ width: "50px" }}><img src="/img/expeditions/download.svg" /></div>
                                            <div>Tout télécharger</div>
                                        </div>

                                        <div className="d-flex mb-3 cursor-pointer" onClick={() => downloadFile("del_receipt")}>
                                            <div style={{ width: "50px" }}><img src="/img/expeditions/download.svg" /></div>
                                            <div>Preuve de livraison</div>
                                        </div>

                                        <div className="d-flex mb-3 cursor-pointer" onClick={() => downloadFile("cmr_file")}>
                                            <div style={{ width: "50px" }}><img src="/img/expeditions/download.svg" /></div>
                                            <div>CMR</div>
                                        </div>

                                        <div className="d-flex mb-3 cursor-pointer" onClick={() => downloadFile("drawing")}>
                                            <div style={{ width: "50px" }}><img src="/img/expeditions/download.svg" /></div>
                                            <div>Plan de chargement</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseTwo">
                                        Créer documents de transport
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="d-flex mb-3 cursor-pointer"
                                            onClick={() => setUpload({ type: "del_receipt", label: "Preuve de livraison" })}>
                                            <div style={{ width: "50px" }}><img src="/img/expeditions/upload.svg" /></div>
                                            <div>Preuve de livraison</div>
                                        </div>

                                        <div className="d-flex mb-3 cursor-pointer"
                                            onClick={() => setUpload({ type: "cmr_file", label: "CMR" })}>
                                            <div style={{ width: "50px" }}><img src="/img/expeditions/upload.svg" /></div>
                                            <div>CMR</div>
                                        </div>

                                        <div className="d-flex mb-3 cursor-pointer" onClick={searchDrawing}>
                                            <div style={{ width: "50px" }}><img src="/img/expeditions/upload.svg" /></div>
                                            <div>{saving && <ButtonWaiting />}Rechercher le plan</div>
                                        </div>

                                        <div className="d-flex mb-3 cursor-pointer">
                                            <div style={{ width: "50px" }}><img src="/common/edit.png" style={{ width: "24px", height: "24px" }} /></div>
                                            <div>
                                                <a target="_blank"
                                                    href={"http://10.141.44.140:8080/storehouse/delivery-note/new-delivery-note/" + ship.business}>
                                                    Bordereau d'expédition</a></div>
                                        </div>

                                        {user.roles.includes("ROLE_ADMIN") &&
                                            <div className="d-flex mb-3 cursor-pointer">
                                                <div style={{ width: "50px" }}><img src="/common/edit.png" style={{ width: "24px", height: "24px" }} /></div>
                                                <div>
                                                    <a target="_blank"
                                                        href={"/supply-chain/tools/expedition?page=doc&shipment_id="
                                                            + ship.id + "&id=" + form.id + "&type=" + ship.type_pack}>
                                                        Documents transport</a></div>
                                            </div>}

                                        {ship.ue !== "ue" &&
                                            <div className="d-flex mb-3 cursor-pointer" onClick={searchDrawing}>
                                                <div style={{ width: "50px" }}><img src="/common/edit.png" style={{ width: "24px", height: "24px" }} /></div>
                                                <div>
                                                    <a target="_blank" href="/customer-client/custom-invoice">Custom invoice</a></div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            {ship.cancelled !== 1 &&
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true"
                                            aria-controls="panelsStayOpen-collapseThree">
                                            Actions
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show">
                                        <div className="accordion-body">
                                            {
                                                ship &&
                                                <div className="mb-3">
                                                    <a href={"/supply-chain/tools/expedition?page=form&id=" + ship.id} >Modifier la demande d'expédition</a>
                                                </div>
                                            }

                                            { isPurchase && <a href="#" onClick={() => setSendMail(true)}>Envoyer une demande de prix aux fournisseurs</a>}

                                            { (user.roles.includes("ROLE_ADMIN") || user.id === ship.author)&&
                                                <button className="btn btn-outline-danger mt-3 ms-auto me-auto"
                                                    onClick={cancelRequest} disabled={loading}>
                                                    {loading && <ButtonWaiting />}Annuler ma demande</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            <br></br>

                            <div className="text-center mb-3">
                                <a href={"/supply-chain/tools/expedition?page=historic&id=" + ship.id} target="_blank">Historique</a>
                            </div>
                        </Fragment>
                    }
                </div>



                <div className="text-center mt-3">
                    <a href="/supply-chain/tools/expedition?page=tracking&all=true">Revenir au tableau</a>
                </div>

                <div className="text-center mt-3">
                    <a href="/supply-chain/tools/expedition">Revenir à l'accueil</a>
                </div>
            </div>} />

        {/** MAIN */}
        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>

            <div className="position-absolute border p-1 rounded cursor-pointer"
                onClick={() => window.location.href = "/supply-chain/tools/expedition?page=tracking&all=true"}>
                <img src="/common/back_left_icon.png" style={{ width: "30px" }} className="me-2"></img>
                <span>Retour au tableau</span>
            </div>

            {ship && <Fragment>
                <h3 className="text-center">Détail expédition n° EXP{ship.id}</h3>
                <h4 className="text-center mb-4">Aff: {ship.business}</h4>
            </Fragment>}


            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}

            {
                form && <Fragment>


                    {/** 2 PARTS */}
                    <div className="d-flex mt-3">
                        {/** REFERENCES + EXPEDITEUR */}
                        <div className="ms-auto me-auto" style={{ minWidth: "630px" }}>
                            <div style={{ height: "500px" }}>
                                {/** REFERENCES */}
                                <div className="see-underline mb-3" style={{ width: "70%" }}>
                                    <h4>Références</h4>
                                </div>

                                <div style={{ width: "600px", height: "500px" }}>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>N° demande</span>
                                        <input type="text" className="form-control" value={"EXP" + ship.id} readOnly />

                                        <input type="text" className="form-control" value={"en date du: " + getFrFormat(ship.date_request, true)} readOnly />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Demandeur</span>
                                        <input type="text" className="form-control" value={ship.ln_sender + " " + ship.fn_sender} readOnly />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Affaire</span>
                                        <input type="text" className="form-control" value={ship.business} readOnly />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Type de colis</span>
                                        <select className="form-select" value={ship.type_pack} disabled>
                                            <option value="...">Choisissez...</option>
                                            <option value="machine">Machine</option>
                                            <option value="machine">Machine</option>
                                            <option value="focus">Demande Focus (SAV, comptoire, kit...)</option>
                                            <option value="other">Autre colis</option>
                                        </select>
                                    </div>

                                    {machines.length > 0 && <div className="fw-bold mb-3">Machine(s): {machines}</div>}

                                    {ship.type_pack === "focus" && <div className="fw-bold mb-3">{getOtherPack()}</div>}

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Libellé de l'expédition</span>
                                        <input type="text" className="form-control" value={ship.exp_lab} readOnly />
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-check form-check-inline fw-bold" style={{width: "100px"}}>Livraison</div>
                                        
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" defaultChecked={ship.ue === "ue"} disabled />
                                            <label className="form-check-label fw-bold" htmlFor="inlineRadio1">UE</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" defaultChecked={ship.ue === "ext"} disabled />
                                            <label className="form-check-label fw-bold" htmlFor="inlineRadio2">Hors UE</label>
                                        </div>
                                        {/*<div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" value="yes" disabled
                                                checked={ship.packer === 1} />
                                            <label className="form-check-label">Envoi chez l'emballeur</label>
                                        </div>*/}
                                    </div>

                                    <div className="">
                                        <div className="form-check form-check-inline fw-bold" style={{width: "100px"}}>Envoi</div>

                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="packer"
                                                value="1" defaultChecked={ship.packer === 1} disabled />
                                            <label className="form-check-label" htmlFor="inlineRadio1">chez <strong>l'emballeur</strong></label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="packer"
                                                value="2" defaultChecked={ship.packer === 2} disabled/>
                                            <label className="form-check-label" htmlFor="inlineRadio2">chez <strong>le client</strong></label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="packer"
                                                value="3" defaultChecked={ship.packer === 3} disabled/>
                                            <label className="form-check-label" htmlFor="inlineRadio2">chez <strong>B+</strong></label>
                                        </div>
                                    </div>

                                    {ship.packer === 1 && <div className="alert alert-danger fw-bold text-center">
                                        ATTENTION ENVOI CHEZ EMBALLEUR
                                    </div>}

                                </div>
                            </div>

                            <div style={{ height: "350px" }}>
                                {/** EXPEDITEUR */}
                                <div className="see-underline mb-3" style={{ width: "70%" }}>
                                    <h4>Expéditeur</h4>
                                </div>

                                <div style={{ width: "600px" }}>

                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Raison sociale</span>
                                        <input className="form-control" defaultValue={ship.cn_sender} readOnly />
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Adresse</span>
                                        <input className="form-control" defaultValue={ship.address_sender} readOnly />
                                    </div>
                                    {ship.comp_add_sender &&
                                        <div className="input-group mb-2">
                                            <span className="input-group-text" style={{ width: "180px" }}>Adresse comp.</span>
                                            <input className="form-control" defaultValue={ship.comp_add_sender} readOnly />
                                        </div>}
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Ville</span>
                                        <input className="form-control" defaultValue={ship.city_sender} title={ship.city_sender} readOnly />
                                        <span className="input-group-text">Code postal</span>
                                        <input className="form-control" defaultValue={ship.cp_sender} readOnly />
                                        <span className="input-group-text">Pays</span>
                                        <input className="form-control" defaultValue={ship.country_sender} readOnly />
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Nom</span>
                                        <input className="form-control" defaultValue={ship.ln_sender} readOnly />
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Prénom</span>
                                        <input className="form-control" defaultValue={ship.fn_sender} readOnly />
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Tel</span>
                                        <input className="form-control" defaultValue={ship.tel_sender} readOnly />
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Mail</span>
                                        <input className="form-control" defaultValue={ship.mail_sender} readOnly />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <datalist id="decharg_manu"><option value="A la charge de B+ / Non connu&nbsp;" text="A la charge de B+ / Non connu&nbsp;"></option><option value="A la charge de B+ / Par l’arrière&nbsp;" text="A la charge de B+ / Par l’arrière&nbsp;"></option><option value="A la charge de B+ / Par le côté&nbsp;" text="A la charge de B+ / Par le côté&nbsp;"></option><option value="A la charge du client / Non connu" text="A la charge du client / Non connu"></option><option value="A la charge du client / Par l’arrière&nbsp;" text="A la charge du client / Par l’arrière&nbsp;"></option><option value="A la charge du client / Par le côté&nbsp;" text="A la charge du client / Par le côté&nbsp;"></option></datalist>
                        <datalist id="emb_aff"><option value="A la charge de B+ / Air 2B" text="A la charge de B+ / Air 2B"></option><option value="A la charge de B+ / Air 4B" text="A la charge de B+ / Air 4B"></option><option value="A la charge de B+ / Mer 2C" text="A la charge de B+ / Mer 2C"></option><option value="A la charge de B+ / Mer 4C" text="A la charge de B+ / Mer 4C"></option><option value="A la charge de B+ / Standard" text="A la charge de B+ / Standard"></option><option value="A la charge du client / Air 2B" text="A la charge du client / Air 2B"></option><option value="A la charge du client / Air 4B" text="A la charge du client / Air 4B"></option><option value="A la charge du client / Mer 2C" text="A la charge du client / Mer 2C"></option><option value="A la charge du client / Standard" text="A la charge du client / Standard"></option></datalist>

                        {/** COMMANDE + DESTINATAIRE */}
                        <div className="ms-auto me-auto" style={{ minWidth: "630px" }}>
                            <div style={{ minWidth: "600px", height: "500px" }}>
                                {/** INFORMATIONS */}
                                {
                                    form.id &&
                                    <div style={{ minHeight: "350px" }}>
                                        <div className="see-underline mb-3" style={{ width: "70%" }}>
                                            <h4>Commande transitaire</h4>
                                        </div>


                                        <div className="d-flex">
                                            <div className="col-6 pe-2">
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ width: "150px" }}>
                                                        {searchOrder && <ButtonWaiting />}
                                                        Transporteur</span>
                                                    <input type="text" className="form-control" value={orderFocus.header && orderFocus.header.RSF1C} readOnly />
                                                </div>

                                                <div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ width: "150px" }}>N° de commande</span>
                                                    <input type="text" className="form-control"
                                                        onChange={(e) => onFormChange("order_nb", e)}
                                                        value={form.order_nb} readOnly />
                                                </div>

                                                <div className="input-group mb-3">
                                                    <div className="input-group-text" style={{ width: "150px" }}>
                                                        <input className="form-check-input mt-0 ms-auto me-3" type="checkbox"
                                                            onChange={(e) => onFormChange("processing", e)} checked={form.processing === "yes"} />
                                                    </div>
                                                    <input type="text" className="form-control" defaultValue="Norme NIMP15 exigée" readOnly />
                                                </div>

                                                <div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ width: "150px" }}>N° de suivi</span>
                                                    <input type="text" className="form-control"
                                                        onChange={(e) => onFormChange("shipment_ref", e)}
                                                        value={form.shipment_ref} readOnly={!isSupplyChain} />
                                                </div>
                                            </div>

                                            <div className="col-6">

                                                <div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ width: "180px" }}>Téléphone</span>
                                                    <input type="text" className="form-control"
                                                        value={orderFocus.header && orderFocus.header.TELFC} readOnly />
                                                </div>

                                                <div className="d-flex mb-3 cursor-pointer" onClick={() => downloadFile("del_receipt")}>
                                                    <div style={{ width: "50px" }}><img src="/img/expeditions/download.svg" /></div>
                                                    <div>Télécharger preuve de livraison</div>
                                                </div>

                                                <div className="d-flex mb-3 cursor-pointer" onClick={() => downloadFile("cmr_file")}>
                                                    <div style={{ width: "50px" }}><img src="/img/expeditions/download.svg" /></div>
                                                    <div>Télécharger CMR</div>
                                                </div>

                                                {(isSupplyChain && !form.drawing) &&
                                                    <div className="text-center">
                                                        <button className="btn btn-outline-secondary" onClick={searchDrawing} disabled={saving}>
                                                            {saving && <ButtonWaiting />}
                                                            Rechercher le plan
                                                        </button>
                                                    </div>
                                                }

                                                {form.drawing && <div>
                                                    <div>Plan sélectionné : {form.drawing.substring(form.drawing.lastIndexOf("\\") + 1, form.drawing.length)}</div>
                                                    {isSupplyChain && <div><a href="#" className="text-primary" onClick={() => selectDrawing(null)}>Uploader un autre plan</a></div>}
                                                </div>}

                                            </div>
                                        </div>
                                    </div>
                                }

                                {!form.id && <div>
                                    {(isSupplyChain && !form.drawing) &&
                                        <div className="text-center">
                                            <button className="btn btn-outline-secondary" onClick={searchDrawing} disabled={saving}>
                                                {saving && <ButtonWaiting />}
                                                Rechercher le plan
                                            </button>
                                        </div>
                                    }

                                    {form.drawing && <div>

                                        <div className="d-flex mb-3 cursor-pointer" onClick={() => downloadFile("drawing")}>
                                            <div style={{ width: "50px" }}><img src="/img/expeditions/download.svg" /></div>
                                            <div>Plan de chargement</div>
                                        </div>
                                        {isSupplyChain && <div><a href="#" className="text-danger" onClick={() => selectDrawing(null)}>Choisir un autre plan</a></div>}
                                    </div>}
                                </div>}

                            </div>
                            {/** DESTINATAIRE */}
                            <div className="see-underline mb-3" style={{ width: "70%" }}>
                                <h4>Destinataire</h4>
                            </div>

                            <div style={{ width: "600px" }}>
                                <div style={{ width: "600px" }}>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Raison sociale</span>
                                        <input className="form-control" defaultValue={ship.cn_recip} readOnly />
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Adresse</span>
                                        <input className="form-control" defaultValue={ship.address_recip} readOnly />

                                        <span className="input-group-text">CP</span>
                                        <input className="form-control" defaultValue={ship.cp_recip} readOnly />

                                    </div>
                                    {ship.comp_add_recip &&
                                        <div className="input-group mb-2">
                                            <span className="input-group-text" style={{ width: "180px" }}>Adresse comp.</span>
                                            <input className="form-control" defaultValue={ship.comp_add_recip} readOnly />
                                        </div>}

                                    <div className="input-group mb-2">

                                        <span className="input-group-text" style={{ width: "180px" }}>Ville</span>
                                        <input type="text" className="form-control" defaultValue={ship.city_recip} readOnly />

                                        <span className="input-group-text">Pays</span>
                                        <input className="form-control" defaultValue={ship.country_recip} readOnly />
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Nom</span>
                                        <input className="form-control" defaultValue={ship.ln_recip} readOnly />
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Prénom</span>
                                        <input className="form-control" defaultValue={ship.fn_recip} readOnly />
                                    </div>
                                    <div className="input-group mb-2">
                                        <span className="input-group-text" style={{ width: "180px" }}>Tel</span>
                                        <input className="form-control" defaultValue={ship.tel_recip} readOnly />
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Mail</span>
                                        <input className="form-control" defaultValue={ship.mail_recip} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** DETAIL DEMANDE */}

                    <div className="mt-3">
                        <div className="ms-auto me-auto" style={{ width: "630px" }}>
                            <div>
                                <div className="see-underline mb-3">
                                    <h4>Détail demande de transport</h4>
                                </div>
                            </div>

                            <div style={{ width: "500px" }}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "180px" }}>N° demande transp.</span>
                                    <input type="text" className="form-control" value={form.id ? "TRANS" + form.id : "Fourni à l'envoi"} readOnly />
                                </div>

                                {ship.type_pack === "machine" && <Fragment>
                                    { ship.packer === 2 && <Fragment>
                                        <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Type d'emballage</span>
                                        <input type="text" className="form-control" value={businessInfo.emb_aff} readOnly />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Décharg. et manut.</span>
                                        <input type="text" className="form-control" readOnly value={businessInfo.decharg_manu} />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Transport</span>
                                        <input type="text" className="form-control" readOnly value={businessInfo.transp_aff} />
                                    </div>                                        
                                    </Fragment>}


                                { ship.packer !== 2 && <Fragment>
                                        <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Type d'emballage</span>
                                        <input type="text" className="form-control" value="A la charge de B+ / Standard" readOnly />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Décharg. et manut.</span>
                                        <input type="text" className="form-control" readOnly value="A la charge de B+ / Par le côté" />
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Transport</span>
                                        <input type="text" className="form-control" readOnly value="A la charge de B+ / Camion" />
                                    </div>                                        
                                    </Fragment>}
                                    
                                </Fragment>}


                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "180px" }}>Date de chargement</span>
                                    <input type="date" className="form-control" readOnly value={getDate(ship.date_load, "en")} />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{ width: "180px" }}>Date de livraison</span>
                                    <input type="date" className="form-control" readOnly value={getDate(ship.date_del, "en")} />
                                </div>

                                {form.sc_user &&
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Demande traitée par</span>
                                        <input type="text" className="form-control" readOnly value={form.sc_user} />
                                    </div>}

                            </div>
                        </div>
                        <div className="d-flex flex-wrap">
                            {shipDetail.map((v, k) => {
                                return <div className="card" style={{ minWidth: "500px" }} key={"t" + k}>
                                    {isSupplyChain &&
                                        <button type="button" className="btn-close ms-auto mb-2"
                                            aria-label="Close" onClick={() => deleteShipping(k)}></button>}

                                    {v.type && <h6 className="text-center">{v.type + " " + (k + 1)}</h6>}

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Type de transport</span>
                                        <select className="form-select" value={v.type && v.type.trim()}
                                            onChange={(e) => onShippingChange(e, k, "type")} disabled={!isSupplyChain}>
                                            <option value="">Choisissez...</option>

                                            {(!truckChoosen || truckChoosen !== "no") && <option value="Camion">Camion</option>}

                                            {(!truckChoosen || truckChoosen !== "yes") && <Fragment>
                                                <option value="Palette/caisse">Palette/caisse</option>
                                                <option value="Autres">Autres</option>
                                            </Fragment>}
                                        </select>
                                    </div>

                                    {(truckChoosen && truckChoosen === "yes") && <Fragment>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Camion bâché, chargt.</span>
                                            <input type="text" className="form-control" value={v.truck_load_side} list="side"
                                                onChange={(e) => onShippingChange(e, k, "truck_load_side")} readOnly={!isSupplyChain} />
                                        </div>

                                        <datalist id="side">
                                            <option value="Par l'arrière"></option>
                                            <option value="Sur le côté"></option>
                                        </datalist>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Date de chargt.</span>
                                            <input type="datetime-local" className="form-control"
                                                value={getDateTimeFromDb(v.truck_load, "en")}
                                                onChange={(e) => onShippingChange(e, k, "truck_load")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Date de déchargt.</span>
                                            <input type="datetime-local" className="form-control"
                                                value={getDateTimeFromDb(v.truck_unload, "en")}
                                                onChange={(e) => onShippingChange(e, k, "truck_unload")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Type de camion</span>
                                            <input type="text" className="form-control" value={v.truck_type} list="truck_type"
                                                onChange={(e) => onShippingChange(e, k, "truck_type")} readOnly={!isSupplyChain} />
                                        </div>

                                        <datalist id="truck_type">
                                            <option value="TAULINER"></option>
                                            <option value="MEGA"></option>
                                            <option value="S2C"></option>
                                        </datalist>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Hauteur de camion (m)</span>
                                            <input type="number" className="form-control" value={v.truck_height}
                                                onChange={(e) => onShippingChange(e, k, "truck_height")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Nb de sangles</span>
                                            <input type="text" className="form-control" value={v.nb_strap}
                                                onChange={(e) => onShippingChange(e, k, "nb_strap")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Poids marchandise (t)</span>
                                            <input type="number" className="form-control" value={v.truck_weight}
                                                onChange={(e) => onShippingChange(e, k, "truck_weight")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Mètres linéaires</span>
                                            <input type="text" className="form-control" value={v.truck_meter}
                                                onChange={(e) => onShippingChange(e, k, "truck_meter")} readOnly={!isSupplyChain} />
                                        </div>
                                    </Fragment>}

                                    {(truckChoosen && truckChoosen === "no") && <Fragment>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Longueur (cm)</span>
                                            <input type="number" className="form-control" value={v.pack_width}
                                                onChange={(e) => onShippingChange(e, k, "pack_width")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Largeur (cm)</span>
                                            <input type="text" className="form-control" value={v.pack_height}
                                                onChange={(e) => onShippingChange(e, k, "pack_height")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Hauteur (cm)</span>
                                            <input type="text" className="form-control" value={v.truck_height}
                                                onChange={(e) => onShippingChange(e, k, "truck_height")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Poids (kg)</span>
                                            <input type="text" className="form-control" value={v.pack_weight}
                                                onChange={(e) => onShippingChange(e, k, "pack_weight")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Date d'enlèvement</span>
                                            <input type="date" className="form-control" value={v.pack_pick_date}
                                                onChange={(e) => onShippingChange(e, k, "pack_pick_date")} readOnly={!isSupplyChain} />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Date de livraison</span>
                                            <input type="date" className="form-control" value={v.pack_delivery}
                                                onChange={(e) => onShippingChange(e, k, "pack_delivery")} readOnly={!isSupplyChain} />
                                        </div>
                                    </Fragment>}

                                    { (v.detail_id && isAdmin) && 
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" style={{ width: "180px" }}>Date d'expédition eff.</span>
                                            <input type="date" className="form-control" value={v.truck_load_real}
                                                onBlur={(e) => updateTruckLoadReal(e, v.detail_id)} />
                                        </div>
                                    }

                                </div>
                            })}

                            {isSupplyChain &&
                                <div className="card cursor-pointer" style={{ minWidth: "500px" }} onClick={addShipping}>
                                    <h5 className="text-center">Ajouter un moyen de transport</h5>
                                    <div className="m-auto">
                                        <img src="/common/add-logo.png" />
                                    </div>
                                </div>}
                        </div>
                    </div>

                    <div className="input-group mb-3 mt-3 ms-auto me-auto" style={{ width: "700px" }}>
                        <span className="input-group-text" style={{ width: "180px" }}>Commentaire</span>
                        <textarea className="form-control" style={{ height: "108px" }} defaultValue={ship.com}
                            onBlur={updateCom}></textarea>
                    </div>

                    <br></br>

                    {(isSupplyChain && ship.sent === 1 && ship.cancelled !== 1) &&
                        <div className="text-center mt-3">
                            <button className="see-btn me-3" style={{ width: "200px" }}
                                onClick={() => send(false)} disabled={saving}>
                                {saving && <ButtonWaiting />}
                                Sauvegarder sans envoyer
                            </button>
                            <button className="see-btn" disabled={saving}
                                onClick={() => send(true)}>
                                {saving && <ButtonWaiting />}
                                Envoyer</button>
                        </div>}
                </Fragment>
            }

            {sendMail && <RequestPrice setSendMail={setSendMail} contacts={contacts} props={p.props}
                businessInfo={businessInfo} shipDetail={shipDetail} ship={ship} form={form}
                purchaser={purchaser} setPurchaser={setPurchaser} />}

            {drawings && <div className="custom-tooltip-v2">
                <div className="modal-body blue-purple-bg">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setDrawings()}></button>

                    <h5 className="text-center mb-3">
                        {drawings.length + (drawings.length > 1 ? " ont été trouvés, sélectionnez le bon" : " a été trouvé, confirmez en cliquant sur celui-ci")}
                    </h5>

                    <ul className="list-group col-6 offset-3">
                        {drawings.map((v, k) => {
                            return <li key={"draw" + k} className="list-group-item" onClick={() => selectDrawing(v.full_path)}>{v.file_name}</li>
                        })}
                    </ul>
                </div>
            </div>}

            {upload && <div className="custom-tooltip-v2">
                <div className="modal-body blue-purple-bg">
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setUpload()}></button>

                    {!shipDetail[0].detail_id && <div className="alert alert-danger text-center fw-bold mt-5">
                        Aucun moyen de transport n'a été enregistré pour permettre d'importer un fichier
                    </div>}

                    {upload.msg && <Fragment><br></br><SuccessMessage msg={upload.msg} /></Fragment>}

                    {shipDetail[0].detail_id !== undefined && <div className="col-6 offset-3">
                        <h5 className="text-center mb-3">Importer {upload.label}</h5>

                        <form onSubmit={uploadFile}>
                            <select className="form-select mb-3" name="detail_id" required>
                                <option value="">Pour quel transport voulez-vous importer ce fichier ?</option>
                                {shipDetail.map((v, k) => {
                                    return <option value={v.detail_id} key={"t" + k}>{v.type + " " + (k + 1)}</option>
                                })}
                            </select>

                            <div className="mb-3">
                                <label htmlFor="formFile" className="form-label fw-bold">Choisissez le fichier</label>
                                <input className="form-control" type="file" id="formFile"
                                    name="file" required accept="application/pdf" />
                            </div>

                            <div className="text-center">
                                <button className="btn btn-success" type="submit" disabled={upload.loading}>
                                    {upload.loading && <ButtonWaiting />}
                                    Enregistrez
                                </button>
                            </div>
                        </form>
                    </div>}

                </div>
            </div>}

            {packerContacts && <SendToPacker packerContacts={packerContacts} setPackerContacts={setPackerContacts}
                machines={machines} ship={ship} businessInfo={businessInfo} form={form}
                shipDetail={shipDetail} sendToPacker={sendToPacker} setSendToPacker={setSendToPacker}
                setSaving={setSaving} setError={setError} props={p.props} saving={saving}
                formOriginChange={formOriginChange} />
            }

        </div>
    </div>

}

export default ShipmentRequest;