import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { hasRole } from "../../../functions/StrFunctions";

const CustomerClient = (props) => {
    const { t } = useTranslation();

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('customer client')}
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">

                <li>
                    <a href="/customer-client/incident-ticket/list?page=new" className="dropdown-item">Ticket incident</a>
                </li>
                <li>
                    <a href="/customer-client/incident-ticket/list?page=list" className="dropdown-item">Liste des tickets</a>
                </li>
                <li><hr className="dropdown-divider" /></li>
               
                    <li>
                        <a href={"/customer-client/picking/list"} className="dropdown-item">Affaires SC</a>
                    </li>
               
                <li>
                    <a href={"/customer-client/picking/spares-bom"} className="dropdown-item">Création d'AV spares</a>
                </li>

                
                <li><hr className="dropdown-divider" /></li>

                <li>
                    <a href={"/customer-client/check-list/maintenance/list"} className="dropdown-item">
                        {t('maintenance check list')}
                    </a>
                </li>

                <li>
                    <a href={"/customer-client/check-list/commissioning"} className="dropdown-item">
                        Commissioning
                    </a>
                </li>

                <li>
                    <a href={"/customer-client/maintenance/resume"} className="dropdown-item">
                        {t('maintenance check list')} overview
                    </a>
                </li>

                <li>
                    <a href={"/customer-client/fat"} className="dropdown-item">
                        FAT
                    </a>
                </li>

                {
                    (props.roles && (props.roles.includes("ROLE_ADMIN") || props.roles.includes("ROLE_ADMIN_BILL")
                        || props.roles.includes("ROLE_CS_BILL"))) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>
                        <a className="dropdown-item disabled dropdown-title text-center fw-bold" href="#">Facturation</a>

                        {(props.roles && (props.roles.includes("ROLE_ADMIN") || props.roles.includes("ROLE_ADMIN_BILL")
                            || props.roles.includes("ROLE_BILL_MNT_REQ"))) &&
                            <li>
                                <a href="/customer-client/bill/maintenance" className="dropdown-item">Services</a>
                            </li>
                        }

                        {(props.roles && (props.roles.includes("ROLE_ADMIN") || props.roles.includes("ROLE_ADMIN_BILL")
                            || props.roles.includes("ROLE_BILL_SPR_REQ"))) &&
                            <li>
                                <a href="/customer-client/bill/spares" className="dropdown-item">Spares</a>
                            </li>
                        }
                    </Fragment>
                }

                <li><hr className="dropdown-divider" /></li>
                <li>
                    <a href="/customer-client/custom-invoice" className="dropdown-item">Facture douanière</a>
                </li>

            </ul>
        </li>
    )

}

export default CustomerClient;