import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { ArticleAutoComplete, isSideBarOpened, SideNavBarV3 } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import Movement from "../../services/storehouse/Movement";
import { addOrRemoveOnCheck, getKey, getKeyInt } from "../../functions/ArrayFunctions";
import { confirmAlert } from "react-confirm-alert";
import { ptToPx } from "../../functions/Styles";

const BpTwoStock = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [list, setList] = useState([]);
    const [checked, setChecked] = useState([]);
    const [value, setValue] = useState("");
    const [pin, setPin] = useState(isSideBarOpened());
    const [tagMode, setTagMode] = useState(false);

    useEffect(() => {
        Movement.getBplus2stock().then(
            (response) => {
                setList(response)
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }, []);

    const addRow = (e) => {
        e.preventDefault();

        var o = {
            id: e.target.id.value,
            ar: e.target.ar.value,
            qty: e.target.qty.value,
            mass: e.target.mass.checked ? 1 : 0,
            obs: "",
            zone: "",
            depart: e.target.depart.value,
            back: null
        }

        setLoading(true);

        Movement.addBplus2stock(o).then(
            (response) => {
                var arr = [...list]
                arr.push(o);
                setList(arr);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();
        
        arr.push(columnHelper.accessor('id', {
            header: '#',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('ar', {
            header: 'AR ou DESCRIPTIF',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('ZN02PA', {
            header: 'PLAN OU REFERENCE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('qty', {
            header: 'QTE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="number" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.id, "qty")} />
            </div>
        }));

        arr.push(columnHelper.accessor('mass', {
            header: 'STOCK DE MASSE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-check-input text-center" type="checkbox" defaultChecked={i.getValue()}
                    onChange={(e) => onFieldChange(e, i.row.original.id, "mass")} />
            </div>
        }));

        arr.push(columnHelper.accessor('obs', {
            header: 'OBSERVATIONS',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="text" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.id, "obs")} />
            </div>
        }));

        arr.push(columnHelper.accessor('zone', {
            header: 'ZONE STOCKAGE DE T à Z',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="text" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.id, "zone")} />
            </div>
        }));

        arr.push(columnHelper.accessor('depart', {
            header: 'DATE DEPART PALETTE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="date" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.id, "depart")} />
            </div>
        }));

        arr.push(columnHelper.accessor('back', {
            header: 'DATE RETOUR PALETTE',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="date" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.original.id, "back")} />
            </div>
        }));

        arr.push(columnHelper.accessor('ZN04PA', {
            header: 'RENVOI',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('x', {
            header: 'Actions',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center no-print p-1" + getCss(i.row.original)}>

                <input className="form-check-input big" type="checkbox"
                    onChange={(e) => checkToDo(e, i.row.id)} defaultChecked={checked.indexOf(i.row.original.id) !== -1}></input>

            </div>
        }));


        return arr;
    }

    function getCss(v) {
        if (v.back !== null && v.back !== "") return " bg-danger-subtle"
        return "";
    }

    const onFieldChange = (e, id, field) => {
        var value = field === "mass" ? (e.target.checked ? 1 : 0) : e.target.value;

        Movement.updateBplus2stock(field, value, id).then(
            () => { },
            (error) => { errorManagement.handleError(props, error) }
        )
    }

    const columns = getColumns();

    const deleteRows = () => {
        confirmAlert({
            title: "Confirmation de suppression",
            message: "Confirmez-vous la suppression de " + checked.length + " article" + (checked.length > 1 ? "s" : ""),
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        Movement.deleteBplus2stock(checked).then(
                            () => { window.location.reload() },
                            (error) => { errorManagement.handleError(props, error); setLoading(false); }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const requestBack = () => {
        confirmAlert({
            title: "Confirmation de rapatriement",
            message: "Confirmez-vous la demande de rapatriement de " + checked.length + " article" + (checked.length > 1 ? "s" : ""),
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        var arr = [];
                        for (let i = 0; i < checked.length; i++) arr.push(list[getKeyInt(list, "id", checked[i])]);

                        setLoading(true);
                        Movement.requestBackBplusTwoRow(arr).then(
                            () => { setMsg("Mail envoyé!"); scrollToTop(); setLoading(false); },
                            (error) => { errorManagement.handleError(props, error); setLoading(false); }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const checkToDo = (e, k) => {
        setChecked(addOrRemoveOnCheck(checked, e, list[k].id))
    }

    return <div className="bg-see navbarV3 right-side">
        <SideNavBarV3 setPin={setPin} content={<div className="ps-3 pe-1">
            <div className="mb-3">
                <a href="#" className="text-dark " onClick={() => window.print()}>Imprimer</a>
            </div>
            
            {checked.length > 0 &&
                <div>
                    {!tagMode && <Fragment>
                        <div className="mb-3">
                            <a href="/settings/contacts-mng?code=BPLUS_2_STOCK" target="_blank" className="text-dark mb-3">Carnet d'adresse du rapatriement</a>
                        </div>

                        <div className="mb-3">
                            <a href="#" className="text-dark mb-3"
                                onClick={requestBack}>Demander le rapatriement</a>
                        </div>

                        <div className="mt-3 mb-3">
                            <a href="#" className="text-dark mb-3"
                                onClick={() => setTagMode(true)}>Imprimer les affiches</a>
                        </div>

                        <div className="mt-3 mb-3">
                            <a href="#" className="text-danger mb-3"
                                onClick={deleteRows}>Supprimer {checked.length} article{checked.length > 1 ? "s" : ""}</a>
                        </div>
                    </Fragment>}


                    {tagMode && <Fragment>
                        <div className="mt-3 mb-3">
                            <a href="#" className="mb-3" onClick={() => setTagMode(false)}>Revenir au tableau</a>
                        </div>
                    </Fragment>}

                </div>
            }

            <br></br>
            
            Légende:
            <div className="bg-danger-subtle p-1 rounded text-center">
                Palette retournée
            </div>
        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            <h4 className={"text-center " + (tagMode ? "no-print" : "")}>Stock B+2</h4>

            {loading && <WaitingRoundSnippers />}<br></br>
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {!tagMode &&
                <div>
                    <form onSubmit={addRow}>
                        <div className="input-group mb-3 no-print">
                        <span className="input-group-text" >#</span>
                        <input type="number" className="form-control" name="id" required />

                            <span className="input-group-text" >Article</span>
                            <ArticleAutoComplete value={value} setValue={setValue} onChooseArt={(v) => { }} />

                            <span className="input-group-text" >Qté</span>
                            <input type="number" className="form-control" name="qty" required />

                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" name="mass" />
                            </div>
                            <input type="text" className="form-control" value="Stock de masse" readonly />

                            <span className="input-group-text" >Départ palette</span>
                            <input type="date" className="form-control" name="depart" required />

                            <button className="btn btn-success" disabled={loading}>
                                {loading && <ButtonWaiting />}
                                Ajouter</button>
                        </div>
                    </form>

                    <ReactTable columns={columns} data={list} top0={true} origin="b2-stock"
                        tdStyle={{ padding: "0" }} />

                </div>
            }

            {tagMode && <div>
                <div className="alert alert-info no-print fw-bold text-center">Paramétrez l'impression avec les marges par défaut pour un meilleur affichage</div>
                
                {list.map((v, k) => {
                    return checked.indexOf(v.id) !== -1 && <table key={"print" + k} 
                        className={ ( checked.indexOf(v.id) < checked.length-1 ? "pagebreak" : "" ) + " text-center font-calibri"}
                        style={{width: "100%", borderCollapse: "collapse"}}>
                        <tr>
                            <td className="border border-dark fw-bold" rowSpan={2} style={{color:"black", fontSize: ptToPx(48)}}>{v.ar}</td>
                            <td className="border border-dark fw-bold" style={{color:"black", fontSize: ptToPx(24)}}>RENVOI</td>
                        </tr>
                        <tr>
                            <td className="border border-dark fw-bold" style={{color:"black", fontSize: ptToPx(36)}}>{v.ZN04PA}</td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{height: "10px"}}></td>
                        </tr>

                        <tr>
                            <td colSpan={2} className="border border-dark fw-bold" style={{color:"black", fontSize: ptToPx(72)}}>QTE: {v.qty}</td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{height: "10px"}}></td>
                        </tr>

                        <tr>
                            <td colSpan={2} className="border border-dark fw-bold" style={{color:"black", fontSize: ptToPx(200)}}>#{v.id}</td>
                        </tr>
                    </table>
                })}

            </div>}

        </div>
    </div>
}

export default BpTwoStock;