import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage, { InfoMsg } from "../../common/smallComponents.js/ErrorMessage";
import { arrayRemoveElt, getKey, sortArrayNumber } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import errorManagement from "../../services/errorManagement";
import { downloadTempFile } from "../../services/functions/FilesProcess";
import Movement from "../../services/storehouse/Movement";
import DeltaScan from "./inventory/DeltaScan";
import FocusTable from "./inventory/FocusTable";
import Miscellaneous from "../../services/common/Miscellaneous";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";

const Inventory = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [list, setList] = useState();
    const [msg, setMsg] = useState();
    const [chosenLoc, setChosenLoc] = useState("");
    const [chosenList, setChosenList] = useState();
    const [error, setError] = useState();
    const [nbElt, setNbElt] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [multipleRes, setMultipleRes] = useState();
    const [article, setArticle] = useState("");
    const user = JSON.parse(localStorage.getItem("user"));
    const n1 = user && (user.roles.includes("ROLE_STOREHOUSE_N1") || user.roles.includes("ROLE_ADMIN"));
    const [step, setStep] = useState("");
    const [users, setUsers] = useState([]);

    const [deltaScanFocus, setDeltaScanFocus] = useState();
    const [info, setInfo] = useState();
    const [focusTable, setfocusTable] = useState();
    const [inInventoryUsers, setinInventoryUsers] = useState([]);
    const [firstCountValidated, setFirstCountValidated] = useState(false);

    useEffect(() => {
        Movement.deltaScanAndFocus().then(
            (res) => {
                setDeltaScanFocus(res)
            }, (error) => {
                setInfo("Aucun article n'a été saisi en inventaire dans Focus");
            }
        )

        Movement.getFocusInventoryTable().then(
            (res) => {
                setUsers(res.users);
                setFirstCountValidated(res.secondCount);

                var arr = [];
                for (let i = 0; i < res.list.length; i++) {
                    if (res.list[i].counter && !arr.includes(res.list[i].counter))
                        arr.push(res.list[i].counter);

                    res.list[i].delta = getDelta(res.list[i]);
                    if (res.list[i].delta !== null && res.list[i].PXREL !== null)
                        res.list[i].delta_price = Math.abs(res.list[i].delta * (res.list[i].PXREL * res.list[i].dol_price));

                    //Hide the row if it's second count
                    if( res.secondCount && res.list[i].second_count !== 1 ) res.list[i].doNotDisplay = true;
                }

                setfocusTable(res.list);
                setinInventoryUsers(arr);

            }, (error) => {
                setInfo("Aucun article n'a été saisi en inventaire dans Focus");
            }
        )

    }, []);

    function getDelta(row) {
        var tot = 0;
        if (row.stock === null) return "";

        if (row.stock_2 !== null) { tot += parseInt(row.stock_2); }
        else { tot += parseInt(row.stock); }

        if (row.b2_qty) tot += parseInt(row.b2_qty);
        if (row.nc) tot += parseInt(row.nc);

        return tot - row.QTSTL;
    }

    const onLocationChoose = (e, val) => {
        e && e.preventDefault();
        var selected = e ? e.target.loc.value : val;

        setChosenLoc(selected);

        var arr = [];
        var cpt = 0;

        var found = false;
        for (let i = 0; i < list.length; i++) {
            var v = list[i];

            if (v.location === selected) {
                found = true;
                cpt++;
                v.total = getQty(v.fnc) + getQty(v.b2) + getQty(v.comatel) + getQty(v.mass) + getQty(v.stock);

                if (arr.length === 0 || arr[arr.length - 1].ar !== v.ar) {
                    v.group = [{ ...v }];
                    arr.push(v);
                } else {
                    arr[arr.length - 1].total += (v.total || 0)
                    arr[arr.length - 1].group.push(v);
                }

            } else if (found) { break; }
        }

        arr = sortArrayNumber(arr, "id", "desc");

        setChosenList(arr);
        setNbElt(cpt);
    }

    useEffect(() => {
        if (chosenList && article !== "") {
            const element = document.querySelector('.bg-warning');
            if (element)
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });

        }
    }, [chosenList]);

    function getQty(val) { if (val === "" || val === 0 || isNaN(parseInt(val))) return 0; return parseInt(val) }

    const clearAllLocation = () => {
        confirmAlert({
            title: "Confirmation",
            message: "Confirmez-vous la suppression de TOUS les contenants ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        Movement.deleteInventoryAllLocations(null);
                        Miscellaneous.updateState("first_count_invt_validated", "0")
                        setChosenLoc();
                        setChosenList();
                        window.location.reload();
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const downloadXls = (value) => {
        setDownloading(true);
        Movement.downloadXls(value).then(
            (r) => {
                downloadTempFile(r)
                setDownloading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            })
    }

    const downloadArchive = () => {
        setDownloading(true);
        Movement.downloadArchive().then(
            (r) => {
                downloadTempFile(r)
                setDownloading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            })
    }

    const onArticleChoose = (ar) => {
        var arr = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].ar.toUpperCase().trim() === ar.toUpperCase().trim() &&
                !arr.includes(list[i].location))
                arr.push(list[i].location)
        }

        if (arr.length === 0) {
            setMultipleRes([]);
        } else if (arr.length === 1) {
            onLocationChoose(null, arr[0])
        } else {
            setMultipleRes(arr)
        }
    }

    const assignUsers = (e) => {
        e.preventDefault();

        if (firstCountValidated) {
            secondAssignment(e)
            return;
        }

        //Get array without qty counted
        var arr = [...focusTable].filter((a) => { return !a.stock })

        //Get checked users
        const formElements = e.target.trigrams;
        const checkedElements = Array.from(formElements).filter((element) => element.type === "checkbox" && element.checked);
        const usersAffected = checkedElements.map((element) => element.value);

        //Check if the nb of counter is upper than article to count
        if (arr.length < usersAffected.length) {
            setError(usersAffected.length + " personnes pour " + arr.length + " articles! Réduisez le nombre de compteurs ou ajoutez des articles");
            return;
        }

        // Get the proportion by user to count
        var nbAr = Math.floor(arr.length / usersAffected.length);
        var cursor = 0;
        var articleCount = 0;

        for (let i = 0; i < arr.length; i++) {
            arr[i].counter = usersAffected[cursor];

            articleCount++;
            if (articleCount >= nbAr && cursor < usersAffected.length - 1) {
                cursor++;
                articleCount = 0;
            }
        }

        //Save in database
        setDownloading(true);
        Movement.assignInventoryToUser(arr).then(
            (r) => {
                window.location.reload();
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setDownloading(false);
            })
    }

    const secondAssignment = (e) => {
        var arr = [...focusTable].filter((a) => { return a.second_count === 1 });

        if (arr.length === 0) {
            setError("Veuillez sélectionner les lignes à recompter");
            return;
        }

        //Get checked users
        const formElements = e.target.trigrams;
        const checkedElements = Array.from(formElements).filter((element) => element.type === "checkbox" && element.checked);
        var usersAffected = checkedElements.map((element) => ({ name: element.value, cpt: 0 }));

        //Check if the nb of counter is upper than article to count
        if (arr.length < usersAffected.length) {
            setError(usersAffected.length + " personnes pour " + arr.length + " articles! Réduisez le nombre de compteurs ou ajoutez des articles");
            return;
        }

        // Get the proportion by user to count
        var nbAr = Math.floor(arr.length / usersAffected.length);

        // Affect different counter for the second
        var finalList = [];
        var notFound = 0;
        for (let i = 0; i < arr.length; i++) {
            var nextUser = getNextUser(usersAffected, arr[i].counter);

            if (nextUser !== null) {
                arr[i].counter_2 = nextUser.name;
                finalList.push(arr[i]);
            } else {
                notFound++;
            }
        }

        if (notFound > 0) {
            setError("Nous devons affecter un compteur différent pour chaque ligne. "
                + "Or il apparait que nous ne pouvons pas affecter " + notFound
                + " article(s) en respectant cette règle. Veuillez choisir un autre compteur");
            return;
        } else {
            //Save in database
            setDownloading(true);
            Movement.assignInventoryToUser(arr, true).then(
                (r) => {
                    window.location.reload();
                }, (error) => {
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                    setDownloading(false);
                })
        }
    }

    function getNextUser(usersAffected, prevCounter) {
        // Filter elements with counter different from previous
        const differentElements = usersAffected.filter(element => element.name !== prevCounter);

        // Find the element with the minimum cpt
        const user = differentElements.reduce((minElement, currentElement) =>
            (minElement === null || currentElement.cpt < minElement.cpt) ? currentElement : minElement,
            null);

        if (user === null) return null;

        var k = getKey(usersAffected, "name", user.name);
        usersAffected[k].cpt++;

        return user;
    }

    const validateFirstCount = () => {

        confirmAlert({
            title: "Fin de tâche",
            message: "Confirmez-vous que tout a été compté et que vous souhaitez passer "
                + "éventuellement à un second comptage ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setDownloading(true);
                        Miscellaneous.updateState("first_count_invt_validated", "1");
                        var toRecount = [];
                        for (let i = 0; i < focusTable.length; i++)
                            if (focusTable[i].delta_price >= 2000) toRecount.push({ ar: focusTable[i].CDARL });

                        if (toRecount.length > 0) {
                            Movement.setSecondCountInventoryTable(toRecount).then(
                                (res) => {
                                    window.location.reload();
                                }, (error) => {
                                    setError(errorManagement.handleError(props, error));
                                    scrollToTop();
                                    setDownloading(false);
                                }
                            )
                        } else {
                            window.location.reload();
                        }

                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const validateSecondCount = () => {
        confirmAlert({
            title: "Fin de tâche",
            message: "Confirmez-vous que tout a été compté et que vous souhaitez passer "
                + "clôturer l'inventaire sur l'intranet et tout passer en attente de validation dans Focus ? "
                + "Les éléments non comptés seront mis à 0 automatiquement !",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setDownloading(true);
                        Movement.importCountInFocusInventoryTable(focusTable).then(
                            (res) => {
                                setMsg("Comptage transféré dans Focus. L'e-mail sera envoyé quand vous aurez validé sur Focus l'inventaire");
                                Movement.archiveFocusInventoryTable(focusTable).then((r)=>console.log(r));
                                scrollToTop();
                                setDownloading(false);
                            }, (error) => {
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                                setDownloading(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    return <div className="bg-see navbarV3 right-side" id="inventory">
        <SideNavBarV3 setPin={setPin} content={<div className="p-2">
            {deltaScanFocus && <div className="mb-3">
                <a href="#" onClick={() => setStep("deltaScan")}>Vérifier écart scan/focus</a>
            </div>}

            {focusTable && <div>
                <h6 className="mb-3">Prix du dollar (billing factor): {focusTable.length > 0 && focusTable[0].dol_price}€</h6>

                <div className="mb-3">
                    <a href="#" onClick={() => setStep("focusTable")}>Tableau d'inventaire Focus</a>
                </div>

                {(focusTable.length > 0 && n1) && <div className="mb-3">
                    <a href="#" onClick={() => setStep("focusTable")}>Contrôle Comptage</a>
                </div>}

                {(step === "focusTable" && n1) && <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Affecter les compteurs
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <form onSubmit={assignUsers}>
                                    {users.map((v, k) => {
                                        return <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="trigrams" value={v.trigram}
                                                defaultChecked={inInventoryUsers.includes(v.trigram)} />
                                            <label className="form-check-label">{v.trigram}</label>
                                        </div>
                                    })}

                                    <div className="text-center">
                                        <button className="btn btn-secondary" disabled={downloading}>
                                            {downloading && <ButtonWaiting />}
                                            Affecter</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {!firstCountValidated && <div className="text-center">
                        <button className="btn btn-warning mt-5" onClick={validateFirstCount} disabled={downloading}>
                            {downloading && <ButtonWaiting />}
                            Valider le premier comptage (aucun effet sur Focus)</button>
                    </div>}

                    {firstCountValidated && <div className="text-center">
                        <button className="btn btn-warning mt-5" onClick={validateSecondCount} disabled={downloading}>
                            {downloading && <ButtonWaiting />}
                            Valider le second comptage (import des comptages dans Focus)</button>
                    </div>}

                </div>}
            </div>}

            {n1 && <Fragment>
                <div className="mt-5">
                    <a href="#" className="text-danger" onClick={clearAllLocation}>Supprimer tous les enregistrements</a>
                </div>
            </Fragment>}

        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>

            {error && <ErrorMessage error={error} errorState={setError} />}
            {info && <InfoMsg info={info} infoState={setInfo} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            <h4 className="text-center">Tableau d'inventaire annuel</h4>

            {step === "" && <div>
                <div className="alert alert-info">
                    <p className="fw-bold">Remarques & Rappels de quelques règles:</p>
                    <ul>
                        <li>Lors de la saisie des articles à inventorier, s'assurer que la case
                            "Prendre en compte les articles dont la quantité en stock est égale à 0" soit
                            décochée</li>

                        <li>Sur le bandeau de gauche, lancer l'analyse des différences entre les articles scannées et les articles inventoriés</li>

                        <li className="text-danger">Avant d'importer le fichier de saisie d'inventaire dans Focus,
                            lancer la fonctionnalité "Correction de la qté. stk. de la fiche article/ qtés. emplac.</li>
                    </ul>
                </div>

                {deltaScanFocus && <div className="d-flex ms-auto me-auto mb-3" style={{ width: "500px" }}>
                    <div className="me-3"><img src="/common/alert_icon.png" /></div>
                    <div className="mt-auto mb-auto fw-bold">Il y a {deltaScanFocus.length} différence(s) entre ce que vous avez scanné et les articles
                        qui seront en inventaire. <a href="#" onClick={() => setStep("deltaScan")}>Vérifiez ici</a>
                    </div>
                </div>}

                {focusTable && <div className="mb-3 text-center">
                    Il y a <strong>{focusTable.length}</strong> articles saisis à inventorier dans Focus. Vous pouvez suivre l'avancement
                    <a href="#" className="ms-1" onClick={() => setStep("focusTable")}>ici</a>
                </div>}

            </div>
            }

            {step === "deltaScan" && <DeltaScan deltaScanFocus={deltaScanFocus} />}
            {step === "focusTable" && <FocusTable focusTable={focusTable} setfocusTable={setfocusTable}
                user={user} firstCountValidated={firstCountValidated} setFirstCountValidated={setFirstCountValidated}
                getDelta={(row) => getDelta(row)} />}


        </div>

    </div>
}

export default Inventory;