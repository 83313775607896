import moment from "moment";
import Moment from "moment";

Moment.locale("fr");

export default function getUrlParam(props, param) {
    return new URLSearchParams(props.location.search).get(param);
}

export function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) { };
}

export function dataURLtoFile(dataurl, filename, callback) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    callback(new File([u8arr], filename, { type: mime }));
}

/**
 * https://stackoverflow.com/questions/19700283/how-to-convert-time-in-milliseconds-to-hours-min-sec-format-in-javascript
 * @param {*} ms 
 * @returns 
 */
export function msToTime(ms, part) {
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (part === "SEC") return seconds;
    else if (part === "MIN") return minutes;
    else if (part === "HOURS") return hours;
    else return days;
}

const monthsNb = {
    0: '01',
    1: '02',
    2: '03',
    3: '04',
    4: '05',
    5: '06',
    6: '07',
    7: '08',
    8: '09',
    9: '10',
    10: '11',
    11: '12'
}

export function currentFrDate() {
    const date = new Date();
    return date.toLocaleDateString("fr")
}

export function currentEnDate() {
    const date = new Date();
    var day = date.getDate();
    if (day < 10) day = "0" + day;
    return date.getFullYear() + "-" + monthsNb[date.getMonth()] + "-" + day;
}

export function getCurrentDateTime(lang) {
    const date = new Date();

    if (lang === "en") return Moment(date).format("yyyy-MM-DD HH:mm");
    return Moment(date).format("DD/MM/yyyy HH:mm")

}

export function getCurrentWeekYear() {
    var o = new Object();

    var currentDate = new Date();
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));

    o.week = Math.ceil(days / 7);
    o.year = currentDate.getFullYear();

    return o;
}

export function cleanString(str) {
    return str
      .toLowerCase()          // Convert to lowercase
      .replace(/[^a-z0-9]/g, ''); // Remove all non-alphanumeric characters
  }

export function getWeekYear(date) {
    var o = new Object();

    var currentDate = new Date(date);
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
        (24 * 60 * 60 * 1000));

    o.week = Math.ceil(days / 7);
    o.year = currentDate.getFullYear();

    return o;
}


export function getFrFormat(date, noHours = false, addHours = false) {
    if (date === null || date === "") return "";

    if( addHours ) date = new Date( date ).addHours(7);

    return noHours ? Moment(date).format("DD/MM/yyyy") : Moment(date).format("DD/MM/yyyy HH:mm")
}


export function getEnFormat(date, noHours = false, addHours = false) {
    if (date === null || date === "") return "";

    if( addHours ) date = new Date( date ).addHours(7);

    return noHours ? Moment(date).format("yyyy-DD-MM") : Moment(date).format("yyyy-DD-MM HH:mm")
}

{/** lang = en or fr */ }
export function getDateTimeFromDb(date, lang, noHours = false) {
    if (date === undefined || date === null || date === "") return "";
    
    var en_format = "YYYY-MM-DD";
    var fr_format = "DD/MM/YYYY";
    
    if( lang === "en" ){
        if( !noHours ) en_format += " HH:mm";
        return Moment(date).format(en_format);
    }else{
        if( !noHours ) fr_format += " HH:mm";
        return Moment(date).format(fr_format)
    }   
    
}

export function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}

Date.prototype.addHours= function(h){
    this.setHours(this.getHours()+h);
    return this;
}

export function getDate(date, lang) {
    if (date === undefined || date === null || date === "") return "";

    if (lang === "en") return Moment(date).format("YYYY-MM-DD");
    return Moment(date).format("DD/MM/YYYY")
}

export function getDaysOfWeek(week, year) {
    var date = moment(year + "W" + (week < 10 ? "0" : "") + week).toDate();
    var arr = new Array();
    arr.push({ en_date: date });

    for (let i = 1; i < 7; i++)
        arr.push({ en_date: moment(date).add(i, "days").toDate() });

    return arr;
}

export function getPreviousMonth(){
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();

    // Adjust for the previous month
    let previousMonth = currentMonth - 1;

    return previousMonth < 0 ? 12 : previousMonth+1;  
}

export function getFirstWeekOfMonthAndYear(month, year) {
    // Create a date object for the first day of the month
    const firstDayOfMonth = new Date(year, month - 1, 1);

    return getWeekNumber(firstDayOfMonth);
}

export function getPreviousYear(){
    let currentDate = new Date();

    // Get the current month (0-11) and year
    let currentMonth = currentDate.getMonth();
    let currentYear = currentDate.getFullYear();

    // Adjust for the previous month
    let previousMonth = currentMonth - 1;

    // If the previous month is January (0), adjust the year
    if (previousMonth < 0) currentYear--; // Decrement the year
    
    return currentYear;
}

function getWeekNumber(d) {
    d = new Date(+d);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    var yearStart = new Date(d.getFullYear(), 0, 1);
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
    return [d.getFullYear(), weekNo];
}

export function weeksInYear(year) {
    var d = new Date(year, 11, 31);
    var week = getWeekNumber(d)[1];
    return week == 1 ? 52 : week;
}

/**
 * if date2 > date1 positive (date2 is after date1) else negative
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export function compareTwoDates(date1, date2) {
    if (date1 === null || date2 === null) return null;

    date1 = new Date(date1);
    date2 = new Date(date2);

    let Difference_In_Time = date2.getTime() - date1.getTime();
    return Math.round(Difference_In_Time / (1000 * 3600 * 24));
}

export function compareTwoDatesObj(date1, date2) {
    if (date1 === null || date2 === null) return null;

    date1.setHours(0,0,0,0);
    date2.setHours(0,0,0,0);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    return Math.round(Difference_In_Time / (1000 * 3600 * 24));
}

export function forceString(val) {
    if (val === undefined || val === null) return "";
    return val.toString();
}

export function cleanRef(ref) {
    ref = ref.replace(/Dia/g, "Ø").replace(/Ø/g, "Ø").replace(/À/g, "A").replace(/Ç/g, "C").replace(/É/g, "E").replace(/È/g, "E").replace(
        /è/g, "E").replace(/Ï/g, "I").replace(/Î/g, "I").replace(/Ö/g, "O").replace(/Ô/g, "O").replace(/Ù/g, "U").replace(/à/g, "A")
        .replace(/ç/g, "C").replace(/é/g, "E").replace(/ï/g, "I").replace(/î/g, "I").replace(/ö/g, "O").replace(/ô/g, "O").replace(
            /ù/g, "U").replace(/,/g, ".").replace(/ /g, "").replace("[{}()\\[\\]+*?^$\\\\|_-]", "").replace("/", "").replace(/\+/g,
                "").replace(/=/g, "").replace(/:/g, "").replace(/°/g, "").replace("\\p{Zs}", "").replace(/-/g, "").toUpperCase();

    return ref;
}

export function extractName(str) {
    var name = str.substring(str.indexOf("name=") + 6, str.length);
    name = name.substring(0, name.indexOf("\"")).trim();
    return name
}

export function extractValue(str) {
    if (str.indexOf("value=") === -1) return "";

    var name = str.substring(str.indexOf("value=") + 7, str.length);
    name = name.substring(0, name.indexOf("\"")).trim();
    return name
}

export function createIptStr(str, value) {
    if (str === undefined || str === null) return "";
    return "<input name=\"" + extractName(str) + "\" value=\"" + value + "\" >";
}

export function updateTagValue(str, field, value) {
    if (field === undefined || field === null) return "";

    var part1 = str.indexOf("<" + field + ">");
    if (part1 === -1) return str;

    part1 += field.length + 2;
    part1 = str.substring(0, part1) + value;

    var part2 = str.indexOf("</" + field + ">");
    return part1 + str.substring(part2, str.length);
}

export function extractValueFromTag(str, field) {
    if (str === undefined || str === null) return "";
    var part1 = str.indexOf("<" + field + ">");
    if (part1 === -1) return "";

    return str.substring((part1 + field.length + 2), (str.indexOf("</" + field + ">")));
}

export function extractValueFromTagV2(str, field) {
    if (str === undefined || str === null) return "";
    var part1 = str.indexOf("<" + field + ">");
    if (part1 === -1) return null;

    return str.substring((part1 + field.length + 2), (str.indexOf("</" + field + ">")));
}

export function diffTwoTimeInFr(start, end) {

    if (start && end && start != "" && end != "") {
        var duration = (new Date(end) - new Date(start)) / 1000 / 60;

        var days = Math.floor(duration / 1440); // Get number of days eq
        // duration / 60 / 24
        duration = duration - (days * 1440); // Get remains for calculates
        // minutes

        var hours = Math.floor(duration / 60); // Get Hours of remained
        // duration

        duration = (duration / 60) - hours; // Get what is after comma to
        // calculate minutes (3.66 - 3 =
        // 0,66)

        var minutes = Math.round(duration * 60);


        return days + " jour(s), " + hours + " heure(s), " + minutes + " minute(s)";
    }

    return "";
}

export function numWords(s) {
    if (isNaN(s)) return "N/A";

    var th = ['', 'thousand', 'million', 'billion', 'trillion'];
    var dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    var tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];


    s = s.toString();
    s = s.replace(/[\, ]/g, '');
    if (s != parseFloat(s)) return 'not a number';
    var x = s.indexOf('.');
    if (x == -1) x = s.length;
    if (x > 15) return 'too big';
    var n = s.split('');
    var str = '';
    var sk = 0;

    for (var i = 0; i < x; i++) {
        if ((x - i) % 3 == 2) {
            if (n[i] == '1') {
                str += tn[Number(n[i + 1])] + ' ';
                i++;
                sk = 1;
            } else if (n[i] != 0) {
                str += tw[n[i] - 2] + ' ';
                sk = 1;
            }
        } else if (n[i] != 0) { // 0235
            str += dg[n[i]] + ' ';
            if ((x - i) % 3 == 0) str += 'hundred ';
            sk = 1;
        }
        if ((x - i) % 3 == 1) {
            if (sk)
                str += th[(x - i - 1) / 3] + ' ';
            sk = 0;
        }
    }

    if (x != s.length) {
        var y = s.length;
        str += 'point ';
        for (var i = x + 1; i < y; i++) {

            if ((y - i) % 3 == 2) {
                if (n[i] == '1') {
                    str += tn[Number(n[i + 1])] + ' ';
                    i++;
                    sk = 1;
                } else if (n[i] != 0) {
                    str += tw[n[i] - 2] + ' ';
                    sk = 1;
                }
            } else if (n[i] != 0) { // 0235
                str += dg[n[i]] + ' ';
                if ((y - i) % 3 == 0) str += 'hundred ';
                sk = 1;
            }
            if ((y - i) % 3 == 1) {
                if (sk)
                    str += th[(y - i - 1) / 3] + ' ';
                sk = 0;
            }
        }
    }
    return str.replace(/\s+/g, ' ');

}

/**
 * 
 * @param {*} number 
 * @param {*} locale 'en-US', 'de-DE', 'ru-RU', 'hi-IN', 'de-CH'
 * @param {*} decimal  nb of digit after comma
 * @returns 
 */
export function nbFormat(n, locale, decimal) {
    if (n === undefined || n === null || isNaN(n)) return "N/A";

    var opts = { minimumFractionDigits: decimal };
    return n.toLocaleString(locale, opts);

}


export function updateDrawingUrl(url) {
    url = url.replace("\\\\10.131.129.101\\Partage\\", "")
    url = url.replace("\\\\GEMWFSP01\\Partage\\", "")
    url = url.replaceAll("\\", "_wso");
    url = url.replaceAll(" ", "%20");
    url = url.replaceAll("&", "%26");
    return url;
}

/**
 * Read txt file in /public/files
 * @param {*} file 
 */
export async function readPublicFile(file, setParams) {
    fetch("/files/" + file + ".txt")
        .then((res) => res.text())
        .then((text) => { setParams( JSON.parse( text ) ) })
        .catch((e) => console.error(e));
}

const highlightWords = (item, searchWords) => {
    if( !item ||!searchWords ) return "";

    //const sanitizedText = sanitizeString(text);
    let highlightedText = "";
    for (var key in item) 
        if (item.hasOwnProperty(key)) highlightedText += normalizeString(item[key]) + ", ";

    searchWords.forEach(word => {
        if (word) {
            if (word.length >= 3) {
                const regex = new RegExp(`(${word})`, 'gi');
                highlightedText = highlightedText.replace(regex, '<strong>$1</strong>');
            }
        }
    });

    return highlightedText;
};

export function highlightSubstring(text, highlightWords) {
    if (!highlightWords || !text) return text;

     // Split highlightWords by spaces to allow multiple words
     const wordsArray = highlightWords.split(/\s+/).filter(Boolean); // Split by spaces and remove empty entries

     // Escape special characters for each word and join with |
     const escapedWords = wordsArray.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');
 
     // Create a regex to match any of the highlight words, case-insensitive
     const regex = new RegExp(escapedWords, 'gi');
 
     // Replace the matches with a <mark> tag to highlight
     return text.replace(regex, (match) => `<mark>${match}</mark>`);
}

/********************************************************
 * FUZZY SEARCH
 * 
 ********************************************************/

const normalizeString = (str) => {
    return forceString(str).toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove accents
        .replace(/[^a-z0-9\s]/g, '') // Remove non-alphanumeric characters
        .replace(/s\b/g, '') // Remove trailing 's' for plural handling
        .trim();
};

const calculateRelevanceScore = (item, searchWords) => {
    var arr = [];
    for (var key in item) 
        if (item.hasOwnProperty(key)) arr.push( normalizeString(item[key]) );
    
    let score = 0;
    searchWords.forEach(word => {
        if (word.length >= 3) {
            for( let i=0; i<arr.length; i++ )
                if( arr[i].includes(word) ) score++;
        }
    });

    return score;
};

export function fuzzySearch( items, searchText ){
    if (!searchText || searchText.length < 3) return [];

    const normalizedSearchText = normalizeString(searchText);
    const searchWords = normalizedSearchText.split(/\s+/).filter(word => word.length > 0);

    const scoredItems = items.map((item, k) => ({
        ...item,
        score: calculateRelevanceScore(item, searchWords),
        highlightedTxt: highlightWords(item, searchWords),
        key: k
    }));

    return scoredItems
        .filter(item => item.score > 0) // Filter out items with no matches
        .sort((a, b) => b.score - a.score); // Sort by score in descending order
}

/**
 * SEE policy
 * @param {*} password 
 * @returns 
 */
export function pwdLength(password){ return password.length >= 12; }
export function hasUppercase(password){ return /[A-Z]/.test(password) }
export function hasLowercase(password){ return /[a-z]/.test(password) }
export function hasNumber(password){ return /\d/.test(password) }
export function hasSpecialChar(password){ return /[!@#$%^&*(),.?":{}|<>]/.test(password) }
export function differentPrevious(pwd, prev){ return !prev.includes( pwd.toUpperCase().trim() ) }

export function validatePassword(password, prev) {
    // Check if password is at least 12 characters long
    if (password.length < 12) return false;

    // Regular expressions to check for required characters
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isDifferent = !prev.includes( password.toUpperCase().trim() );

    // Check if all conditions are met
    return hasUppercase && hasLowercase && hasNumber && hasSpecialChar && isDifferent;
}

/**
 * 
 * @param {*} item word
 * @param {*} searchWords 
 * @returns 
 */
export function getRelevanceScore(item, searchText){
    var arr = [];
    for (var key in item) 
        if (item.hasOwnProperty(key)) arr.push( normalizeString(item[key]) );
    
    let score = 0;
    const normalizedSearchText = normalizeString(searchText);
    const searchWords = normalizedSearchText.split(/\s+/).filter(word => word.length > 0);

    searchWords.forEach(word => {
            for( let i=0; i<arr.length; i++ )
                if( arr[i].includes(word) ) score++;
    });

    return score;
};

/**
 * 
 * @param {*} string 
 * @param {*} eachWord Appy it after each space ?
 * @returns 
 */
export function capitalizeFirstLetter(string, eachWord) {
    if( !string || string.length < 2 ) return string;


    if( !eachWord ){ return capitalize(string); }
    else{
        var stringArray = string.split(" ");
        var finalString = "";

        for(let i=0; i<stringArray.length; i++)
            finalString += capitalize( stringArray[i] ) + " ";

        return finalString.trim();
    }

}

function capitalize(string){
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function removeSpecialCharacters(str) {
    return str.replace(/[^a-zA-Z0-9 ]/g, '');
}

export function getCurrentUser(){
    return JSON.parse(localStorage.getItem("user"));
}

/**
 * roles is an array
 */
export function hasRole( roles ){
    const user = JSON.parse(localStorage.getItem("user"));
    if( !user ) return false;
    
    return roles.some( v => user.roles.includes( v ) );
}

export function isObjectNotEmpty(obj) {
    return Object.values(obj).every(value => value !== null && value !== undefined && value.trim() !== '');
}

export function isAtLeastOneNotEmpty(obj) {
    return Object.values(obj).some(value => value !== null && value !== undefined && value.trim() !== '');
}

/**
 * 
 * @param {*} obj 
 * @param {*} expections array
 * @returns 
 */
export function isAtLeastOneNotEmptyExcept(obj, expections) {
    let isEmpty = false;
    
    Object.entries(obj).some( v => {
        if( !expections.includes( v[0] ) && ( v[1] !== null && v[1] !== undefined && v[1].trim() === '' ) ){
            isEmpty = true;          
    }})

    return isEmpty;
}
